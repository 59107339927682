/* ===========================
Collection
=========================== */

.collection-cover {
	padding: rem(16) 0 rem(24) 0;
	background: var(--gray-10);
	background-size: cover;
	background-position: center;

	@include breakpoint(min-768) {
		padding-bottom: rem(36);
	}

	@include breakpoint(min-1024) {
		padding-bottom: rem(48);
	}

	&__header {
		color: var(--gray-90);
		font-size: var(--font-size-8);
		font-family: $font-stack-display;
		line-height: rem(32);
		font-weight: var(--font-weight-regular);
		text-align: center;
		max-width: 36ch;
		display: block;
		margin: 0 auto rem(12) auto;

		@include breakpoint(min-768) {
			font-size: var(--font-size-9);
			line-height: rem(36);
		}

		@include breakpoint(min-1024) {
			font-size: var(--font-size-10);
			line-height: rem(48);
			margin-bottom: rem(24);
		}
	}

	&__desc {
		font-size: var(--font-size-4);
		width: 100%;
		max-width: 60ch;
		text-align: center;
		line-height: rem(24);
		display: block;
		margin: 0 auto rem(24) auto;
		color: var(--gray-70);

		@include breakpoint(min-768) {
			font-size: var(--font-size-5);
			line-height: rem(28);
			margin: 0 auto rem(44) auto;
		}

		@include breakpoint(min-1024) {
			margin: 0 auto rem(52) auto;
		}
	}

	&--gifts {
		background-color: #F1D5E5;

		@include breakpoint(min-640) {
			background-image: url(../../../dist/images/collection/covers/gifts-t.jpg);
			@include image-2x("../../../dist/images/collection/covers/gifts-t@2x.webp", cover);
		}

		@include breakpoint(min-960) {
			background-image: url(../../../dist/images/collection/covers/gifts-d.jpg);
			@include image-2x("../../../dist/images/collection/covers/gifts-d@2x.webp", cover);
		}

		@include breakpoint(min-1280) {
			background-image: url(../../../dist/images/collection/covers/gifts-dw.jpg);
			@include image-2x("../../../dist/images/collection/covers/gifts-dw@2x.webp", cover);
		}
	}

	.webp &--gifts {
		background-color: #F1D5E5;

		@include breakpoint(min-640) {
			background-image: url(../../../dist/images/collection/covers/gifts-t.webp);
			@include image-2x("../../../dist/images/collection/covers/gifts-t@2x.webp", cover);
		}

		@include breakpoint(min-960) {
			background-image: url(../../../dist/images/collection/covers/gifts-d.webp);
			@include image-2x("../../../dist/images/collection/covers/gifts-d@2x.webp", cover);
		}

		@include breakpoint(min-1280) {
			background-image: url(../../../dist/images/collection/covers/gifts-dw.webp);
			@include image-2x("../../../dist/images/collection/covers/gifts-dw@2x.webp", cover);
		}
	}

	&--grandchild-gifts {
		background-color: #F1D5E5;

		@include breakpoint(min-640) {
			background-image: url(../../../dist/images/promo/grandchild-gift/grandchild-gift-cover-t.jpg);
			@include image-2x("../../../dist/images/promo/grandchild-gift/grandchild-gift-cover-t@2x.webp", cover);
		}

		@include breakpoint(min-960) {
			background-image: url(../../../dist/images/promo/grandchild-gift/grandchild-gift-cover-d.jpg);
			@include image-2x("../../../dist/images/promo/grandchild-gift/grandchild-gift-cover-d@2x.webp", cover);
		}

		@include breakpoint(min-1280) {
			background-image: url(../../../dist/images/promo/grandchild-gift/grandchild-gift-cover-dw.jpg);
			@include image-2x("../../../dist/images/promo/grandchild-gift/grandchild-gift-cover-dw@2x.webp", cover);
		}
	}

	.webp &--grandchild-gifts {
		background-color: #F1D5E5;

		@include breakpoint(min-640){
			background-image: url(../../../dist/images/promo/grandchild-gift/grandchild-gift-cover-t.webp);
			@include image-2x("../../../dist/images/promo/grandchild-gift/grandchild-gift-cover-t@2x.webp", cover);
		}

		@include breakpoint(min-960){
			background-image: url(../../../dist/images/promo/grandchild-gift/grandchild-gift-cover-d.webp);
			@include image-2x("../../../dist/images/promo/grandchild-gift/grandchild-gift-cover-d@2x.webp", cover);
		}

		@include breakpoint(min-1280){
			background-image: url(../../../dist/images/promo/grandchild-gift/grandchild-gift-cover-dw.webp);
			@include image-2x("../../../dist/images/promo/grandchild-gift/grandchild-gift-cover-dw@2x.webp", cover);
		}
	}
}

.c-collection-cover-subcat {
	list-style-type: none;
	margin: rem(20) 0 0;
	padding: 0;
	display: flex;
	flex-direction: column;
	flex-wrap: wrap;

	@include breakpoint(min-640) {
		flex-direction: row;
		justify-content: center;
		margin: rem(40) 0 0;
	}
}

.c-collection-cover-subcat__item {
	cursor: pointer;
	background: var(--white);
	border-radius: rem(2);
	margin-bottom: rem(4);
	position: relative;
	padding: rem(8);

	&:hover {
		box-shadow: 0 rem(1) rem(20) 0 rgba(26, 22, 18, 0.15);
	}

	&:last-child {
		margin-bottom: 0;
	}

	@include breakpoint(min-640) {
		margin: rem(4);
		flex: 0 0 rem(180);
		padding: rem(20);

		&:last-child {
			margin: rem(4);
		}
	}
}

.c-collection-cover-subcat__item-container {
	display: flex;
	height: 100%;

	@include breakpoint(min-640) {
		flex-direction: column;
		align-content: stretch;
	}
}

.c-collection-cover-subcat__item-image {
	flex: 0 0 rem(40);
	margin-right: rem(24);
	max-width: rem(60);

	@include breakpoint(min-640) {
		flex: 0 0 rem(100);
		margin: 0 auto rem(12) auto;
		display: block;
		max-width: 100%;
	}
}

.c-collection-cover-subcat__item-name {
	align-self: center;
	flex: 1 1 100%;
	width: 100%;
	height: 100%;

	@include breakpoint(min-640) {
		text-align: center;
	}

	h2 {
		font-size: var(--font-size-4);
		line-height: rem(20);
		font-family: $font-stack-text;
		margin-bottom: 0;

		@include breakpoint(min-640) {
			font-size: var(--font-size-3);
			line-height: rem(16);
		}
	}

	a {
		color: var(--gray-90);
		text-decoration: none;
		display: block;
	}
}

.collection-empty {
	padding: rem(20) 0 rem(20) 0;

	@include breakpoint(min-768) {
		padding: rem(40) 0 rem(20) 0;
	}
}

.collection-empty__header {
	text-align: center;
	margin-bottom: rem(12);
}

.collection-empty__desc {
	color: var(--gray-70);
	max-width: 60ch;
	margin: 0 auto rem(40) auto;
	text-align: center
}

.collection-empty__button {
	max-width: rem(300);
	margin: 0 auto;
	display: block;
}

.collection-pagelinks {
	display: flex;
	flex-direction: column;
	margin: 0 0 rem(40) 0;
	padding: rem(16) 0;
	position: relative;

	@include breakpoint(min-1600) {
		flex-direction: row;
		margin: rem(60) 0 rem(120) 0;
	}
}

.collection-pagelinks__top {
	display: none;
	position: relative;
	-webkit-appearance: none;
	appearance: none;
	border: none;
	font-weight: var(--font-weight-semibold);
	font-size: var(--font-size-2);
	padding-left: rem(20);
	line-height: rem(28);
	color: var(--pink-70, #7A3040);

	&:hover {
		text-decoration: underline;
	}

	.icon {
		position: absolute;
		width: rem(12);
		height: rem(12);
		transform: rotate(-90deg);
		left: 0;
		top: rem(8);
		fill: var(--pink-70, #7A3040);
	}

	@include breakpoint(min-1600) {
		display: block;
	}
}

.collection-pagelinks__show-more {
	margin: 0 auto rem(24) auto;
	width: 100%;
	max-width: rem(260);

	@include breakpoint(min-1600) {
		position: absolute;
		left: 50%;
		margin-left: - rem(150);
		top: rem(6);
	}
}

.collection-pagelinks__paginator {
	display: flex;
	align-self: center;
	margin: 0;
	padding: 0;
	list-style-type: none;

	@include breakpoint(min-1600) {
		margin-left: auto;
	}
}

.collection-pagelinks__paginator-item {
	display: inline-block;
	background: var(--white);
	text-align: center;
	line-height: rem(28);
	font-weight: var(--font-weight-semibold);
	text-decoration: none;
	padding: 0 rem(8);
	transition: background-color .2s $ease-in-out-quad, color .2s $ease-in-out-quad;
}

.collection-pagelinks__paginator-item--link,
.collection-pagelinks__paginator-item--first,
.collection-pagelinks__paginator-item--last {
	width: rem(28);
	height: rem(28);
	padding: 0;
}

.collection-pagelinks__paginator-item--first {
	margin-right: rem(30);
}

.collection-pagelinks__paginator-item--first::after {
	content: "…";
	position: absolute;
	right: - rem(20);
	top: 0;
}

.collection-pagelinks__paginator-item--last {
	margin-left: rem(30);
}

.collection-pagelinks__paginator-item--last::before {
	content: "…";
	position: absolute;
	left: - rem(20);
	top: 0;
}

.collection-pagelinks__paginator-item--actual {
	background: var(--gray-90);
	color: var(--white);
}

.collection-pagelinks__paginator a {
	display: block;
	color: var(--gray-70);

	&:hover {
		color: var(--gray-90);
		background: var(--gray-20);
	}
}

.collection-bottom {
	background: var(--gray-10);
	padding: rem(40) 0 rem(60) 0;

	> .page-wrap {
		position: relative;
	}

	@include breakpoint(min-768) {
		padding: rem(60) 0 rem(80) 0;
	}

	@include breakpoint(min-960) {
		padding: rem(80) 0 rem(100) 0;
	}

	@include breakpoint(min-1280) {
		padding: rem(100) 0 rem(120) 0;
	}
}

.collection-bottom__grid-wrapper {
	display: grid;
	grid-template-columns: 1fr;
	grid-template-rows: auto;
	grid-template-areas:
        "heading"
        "image"
		"adv";

	@include breakpoint(min-1280) {
		grid-template-areas:
        "heading image"
		"adv adv";
	}

	@include breakpoint(min-1600) {
		grid-template-areas:
        "heading image"
		"adv image";
	}
}


.collection-bottom__top-wrapper {
	grid-area: heading;
}
.collection-bottom__heading {
	font-family: $font-stack-display;
	font-weight: var(--font-weight-regular);
	font-size: var(--font-size-8);
	line-height: rem(52);
	text-align: center;
	max-width: 900px;
	color: var(--gray-90);
	display: block;
	margin: 0 auto rem(20) auto;

	@include breakpoint(min-768) {
		font-size: var(--font-size-9);
		line-height: rem(52);
	}

	@include breakpoint(min-1280) {
		text-align: left;
		margin: 0 0 rem(24) 0;
	}

	+ p {
		color: var(--gray-70);
		font-size: var(--font-size-4);
		line-height: rem(24);
		text-align: center;
		margin: 0 auto rem(32) auto;
		max-width: 70ch;
		display: block;

		@include breakpoint(min-768) {
			font-size: var(--font-size-5);
			line-height: rem(28);
			margin: 0 auto rem(52) auto;
		}

		@include breakpoint(min-1280) {
			text-align: left;
			margin: 0 0 rem(80) 0;
		}
	}
}

.collection-bottom__image {
	grid-area: image;
	display: block;
	margin: 0 auto rem(32) auto;

	@include breakpoint(min-768) {
		margin: 0 auto rem(52) auto;
	}

	@include breakpoint(min-1280) {
		margin: 0 auto var(--space-12) var(--space-10);
	}

	img {
		max-width: 100%;
		display: block;
		margin: 0 auto;

		@include breakpoint(min-1280) {
			max-width: rem(560);
			box-shadow: 2.5rem -2.5rem 0 0 var(--white);
		}
	}
}

.collection-bottom__adv {
	grid-area: adv;
	list-style-type: none;
	margin: 0;
	padding: 0;

	@include breakpoint(min-768) {
		display: flex;
		flex-wrap: wrap;
		max-width: rem(768);
		margin: 0 auto;
	}

	@include breakpoint(min-1280) {
		margin: 0;
		max-width: 100%;
	}
}

.collection-bottom__adv-item {
	margin-bottom: var(--space-8);

	&:last-child {
		margin-bottom: 0;
	}

	@include breakpoint(min-768) {
		flex: 1 1 50%;
		max-width: calc(50% - 1rem);
		margin: 0 rem(32) rem(32) 0;

		&:nth-child(2n) {
			margin-right: 0;
		}
	}

	h3 {
		text-transform: uppercase;
		font-size: var(--font-size-4);
		line-height: rem(24);
		text-align: center;
		margin: 0 auto rem(4) auto;
		color: var(--gray-90);
		font-weight: var(--font-weight-semibold);
		max-width: 70ch;

		@include breakpoint(min-768) {
			font-size: var(--font-size-5);
			line-height: rem(28);
			text-align: left;
		}
	}

	p {
		text-align: center;

		@include breakpoint(min-768) {
			text-align: left;
		}
	}

	span {
		display: block;
		text-align: center;
		margin: 0 auto rem(32) auto;
		color: var(--gray-70);
		font-size: var(--font-size-3);
		line-height: rem(20);
		max-width: 70ch;

		@include breakpoint(min-768) {
			font-size: var(--font-size-4);
			line-height: rem(24);
			text-align: left;
		}

		@include breakpoint(min-1280) {
			margin: 0 0 rem(40) 0;
		}
	}

	&:last-child span {
		margin-bottom: 0;
	}
}

.o-products-faq {
	background: var(--gray-10);
	padding: rem(40) 0 rem(60) 0;
}

.o-products-faq h2 {
	text-align: center;
}

.o-products-faq .faq-info-block {
	width: 92%;
	max-width: rem(960);
	margin: 0 auto;

	@include breakpoint(min-1024) {
		width: 100%;
	}
}