/* ===========================
Grand child page
=========================== */
.collection-steplist {
    background: var(--gray-10);
    padding: rem(40) 0 rem(60) 0;

    > .page-wrap {
        position: relative;
        display: flex;
        flex-direction: column;

        @include breakpoint(min-1280) {
            flex-direction: row;
        }
    }

    @include breakpoint(min-768) {
        padding: rem(60) 0 rem(80) 0;
    }

    @include breakpoint(min-960) {
        padding: rem(80) 0 rem(100) 0;
    }

    @include breakpoint(min-1280) {
        padding: rem(100) 0 rem(120) 0;
    }

    &__content {
        margin-bottom: rem(40);
    }

    &__heading {
        font-family: $font-stack-display;
        font-weight: var(--font-weight-regular);
        font-size: var(--font-size-8);
        line-height: rem(52);
        text-align: center;
        max-width: 70ch;
        color: var(--gray-90);
        display: block;
        margin: 0 auto rem(20) auto;

        @include breakpoint(min-768) {
            font-size: var(--font-size-9);
            line-height: rem(52);
            max-width: percent(640, 768);
        }

        @include breakpoint(min-960) {
            max-width: percent(640, 960);
        }

        @include breakpoint(min-1280) {
            margin: 0 0 rem(24) 0;
            text-align: left;
        }
    }

    &__desc {
        color: var(--gray-70);
        font-size: var(--font-size-4);
        line-height: rem(24);
        text-align: center;
        margin: 0 auto rem(44) auto;
        max-width: 70ch;
        display: block;

        @include breakpoint(min-1280) {
            text-align: left;
            margin-bottom: rem(60);
        }
    }

    &__image {
        display: block;
        margin: 0 auto rem(32) auto;

        @include breakpoint(min-768) {
            margin: 0 auto rem(52) auto;
        }

        @include breakpoint(min-1280) {
            position: absolute;
            top: 0;
            right: rem(40);
        }

        img {
            max-width: 100%;
            display: block;
            margin: 0 auto;

            @include breakpoint(min-1280) {
                max-width: rem(560);
                box-shadow: 2.5rem -2.5rem 0 0 var(--white);
            }
        }
    }

    &__list {
        margin: 0 auto 0 rem(20);
        padding: 0;
        max-width: 55ch;
        counter-reset: step;

        @include breakpoint(min-640) {
            margin: 0 auto;
        }

        @include breakpoint(min-1280) {
            margin: 0 auto 0 rem(20);
        }
    }

    &__list-item {
        position: relative;
        padding: 0 0 rem(20) rem(40);
        min-height: rem(75);
        border-left: rem(2) solid var(--pink-30, #EDD5D1);


        &::before {
            counter-increment: step;
            content: counters(step,".") " ";
            position: absolute;
            left: -18px;
            top: 0;
            height: rem(32);
            width: rem(32);
            font-size: var(--font-size-4);
            font-weight: var(--font-weight-semibold);
            line-height: rem(32);
            text-align: center;
            color: var(--pink-90);
            background-color: var(--pink-30, #EDD5D1);
            border: rem(2) solid var(--pink-30, #EDD5D1);
            border-radius: 50%;
            z-index: 2;
        }

        &::marker {
            content: '';
        }

        h3 {
            text-transform: uppercase;
            font-size: var(--font-size-4);
            line-height: rem(24);
            text-align: left;
            margin: 0 auto rem(4) auto;
            color: var(--gray-90);
            font-weight: var(--font-weight-semibold);
            max-width: 70ch;

            @include breakpoint(min-768) {
                font-size: var(--font-size-5);
                line-height: rem(28);
            }
        }

        span {
            display: block;
            text-align: left;
            margin: 0 auto rem(20) auto;
            color: var(--gray-70);
            font-size: var(--font-size-3);
            line-height: rem(20);
            max-width: 70ch;

            @include breakpoint(min-768) {
                font-size: var(--font-size-4);
                line-height: rem(24);
                text-align: left;
            }

            @include breakpoint(min-1280) {
                margin: 0 0 rem(40) 0;
            }
        }

        &:last-child span {
            margin-bottom: 0;
        }

        &:last-child {
            border-left: none;
        }

        &:last-child::before {
            content: '\2713';
        }
    }
}