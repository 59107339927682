/* ===========================
Collections page
=========================== */
.collections-page {
    .collections-cover {
        text-align: center;
        padding: rem(40) 0 0 0;

        @include breakpoint(min-1280) {
            padding: rem(80) 0 0 0;
        }

        h1 {
            font-family: $font-stack-display;
            font-weight: var(--font-weight-regular);
            margin: 0 auto;

            @include breakpoint(min-1280) {
                max-width: rem(660);
                font-size: var(--font-size-10);
                line-height: rem(48);
                margin-bottom: rem(24);
            }
        }
    }

    .collections-section__text p {
        max-width: none;
    }

    .collections-section {
        padding: rem(80) 0;

        @include breakpoint(min-1280) {
            padding: rem(120) 0;
        }

        &.danfil-diamonds {

            .l-collection-section-wrapper {

                @include breakpoint(min-960) {
                    min-height: rem(520);
                }

                @include breakpoint(min-1024) {
                    min-height: rem(640);
                }

                @include breakpoint(min-1600) {
                    min-height: rem(800);
                }
            }

            .collections-section__text {
                h2, p {
                    @include breakpoint(min-960) {
                        padding: 0 calc(#{percent(660,1472)} + #{rem(32)}) 0 0;
                    }
                }
            }

            .collections-section__image {
                @include breakpoint(min-960) {
                    right: 0;
                    width: percent(660,1472);
                }
            }
        }

        &.light-collection {
            background-color: var(--gray-10);

            .collections-section__text {
                @include breakpoint(min-960) {
                    order: 1;
                }

                h2, p {
                    @include breakpoint(min-960) {
                        padding: 0 0 0 calc(#{percent(727,1472)} + #{rem(32)});
                    }
                }

                ul {
                    @include breakpoint(min-960) {
                        justify-content: flex-end;
                    }
                }

                .btn {
                    @include breakpoint(min-960) {
                        margin: 0 0 0 calc(#{percent(727,1472)} + #{rem(32)});
                    }
                }
            }

            .collections-section__image {
                @include breakpoint(min-960) {
                    width: percent(727,1472);
                }
            }
        }

        &.colored-gemstones, &.filip-horak {

            .l-collection-section-wrapper {

                @include breakpoint(min-960) {
                    min-height: rem(520);
                }

                @include breakpoint(min-1024) {
                    min-height: rem(560);
                }

                @include breakpoint(min-1600) {
                    min-height: rem(660);
                }
            }

            .collections-section__text {
                h2, p {
                    @include breakpoint(min-960) {
                        padding: 0 calc(#{percent(660,1472)} + #{rem(32)}) 0 0;
                    }
                }
            }

            .collections-section__image {
                @include breakpoint(min-960) {
                    right: 0;
                    width: percent(660,1472);
                }
            }
        }

        &.wedding-rings, &.cutie-kids {
            background-color: var(--gray-10);

            .l-collection-section-wrapper {

                @include breakpoint(min-960) {
                    min-height: rem(500);
                }

                @include breakpoint(min-1024) {
                    min-height: rem(620);
                }

                @include breakpoint(min-1600) {
                    min-height: rem(780);
                }
            }

            .collections-section__text {
                @include breakpoint(min-960) {
                    order: 1;
                }

                h2, p {
                    @include breakpoint(min-960) {
                        padding: 0 0 0 calc(#{percent(727,1472)} + #{rem(32)});
                    }
                }

                ul {
                    @include breakpoint(min-960) {
                        justify-content: flex-end;
                    }
                }

                .btn {
                    @include breakpoint(min-960) {
                        margin: 0 0 0 calc(#{percent(727,1472)} + #{rem(32)});
                    }
                }
            }

            .collections-section__image {
                @include breakpoint(min-960) {
                    width: percent(660,1472);
                }
            }
        }

        &__text {
            text-align: center;
            max-width: rem(660);
            margin: 0 auto rem(8) auto;
            width: 100%;
            order: 1;
            z-index: $z-index-1;

            @include breakpoint(min-960) {
                order: 0;
                text-align: left;
                margin: 0 0 rem(8) 0;
                max-width: none;
            }

            h2 {
                font-family: $font-stack-display;
                font-weight: var(--font-weight-regular);
                margin-bottom: rem(16);

                @include breakpoint(min-1280) {
                    font-size: var(--font-size-9);
                    line-height: rem(52);
                    margin-bottom: rem(24);
                }
            }

            p {
                color: var(--gray-70);
                font-size: var(--font-size-5);
                line-height: rem(28);
                letter-spacing: -0.6px;
                margin: 0 0 rem(32) 0;
                padding: 0;
            }

            ul {
                width: 100%;
                margin: 0 0 rem(48) 0;
                padding: 0;
                list-style-type: none;
                display: flex;
                flex-wrap: wrap;

                li {
                    width: calc(25% - #{percent(4,960)});
                    max-width: rem(208);
                    margin: 0 percent(4,960) 0 0;

                    &:last-child {
                        margin: 0;
                    }

                    @include breakpoint(min-960) {
                        width: percent(208,1472);
                        max-width: rem(208);
                        margin: 0 percent(8,1472) 0 0;
                    }

                    picture {
                        width: 100%;
                        display: block;
                        line-height: 0;

                        img {
                            width: 100%;
                        }
                    }
                }
            }

            .btn {
                margin: 0 auto;
                max-width: rem(280);

                @include breakpoint(min-960) {
                    margin: 0;
                }
            }
        }

        &__image {
            width: 100%;
            max-width: rem(660);
            margin: 0 auto rem(40) auto;
            line-height: 0;
            background-color: var(--gray-10);

            @include breakpoint(min-960) {
                margin: 0;
                position: absolute;
            }

            img {
                width: 100%;
            }
        }
    }

    //Common wrapper for section
    .l-collection-section-wrapper {
        margin: 0 auto;
        display: flex;
        align-items: center;
        flex-direction: column;
        max-width: rem(1472);
        position: relative;

        @include breakpoint(min-960) {
            flex-direction: row;
        }
    }
}