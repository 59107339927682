/* ===========================
Diamonds and zircons page
=========================== */
.lp-jewelry-guide--diamonds-zircons .o-page-section--diamonds-zircons-comparison {
    max-width: none;
    width: 100%;

    @include breakpoint(min-1280) {
        padding-top: var(--space-40);
    }
}

.lp-jewelry-guide--diamonds-zircons .o-page-section--diamonds-zircons-comparison .o-page-section__image {
    @include breakpoint(min-1280) {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        max-width: none;
    }
}

.lp-jewelry-guide--diamonds-zircons .o-page-section--diamonds-zircons-comparison .o-page-section__content {
    @include breakpoint(min-1280) {
        margin-left: 0;
    }
}

.lp-jewelry-guide--diamonds-zircons .o-page-section--diamonds-zircons-comparison .o-page-section__content.right {
    @include breakpoint(min-1280) {
        margin-left: auto;
    }
}

.lp-jewelry-guide--diamonds-zircons .o-page-section--diamonds-zircons-comparison .o-page-section__image img {
    @include breakpoint(min-1280) {
          width: auto;
        height: 800px;
        min-width: 100%;
    }
}

.lp-jewelry-guide--diamonds-zircons .parameter-list {
    width: 100%;
    display: flex;
    gap: var(--space-4);
    margin: var(--space-6) 0 0 0;
    padding: 0;
    list-style-type: none;
    flex-wrap: wrap;

    @include breakpoint(min-960) {
        flex-wrap: nowrap;
        margin-top: var(--space-16);
    }

    @include breakpoint(min-1280) {
        gap: var(--space-6);
    }
}

.lp-jewelry-guide--diamonds-zircons .parameter-list__item {
    background-color: var(--white);
    padding: var(--space-4);
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    gap: var(--space-4);
    justify-content: center;
    border: var(--space-05) solid var(--gold-20);

    @include breakpoint(min-768) {
        justify-content: flex-start;
        padding: var(--space-6);
        flex-wrap: nowrap;
    }

    @include breakpoint(min-1280) {
        border: none;
    }
}

.lp-jewelry-guide--diamonds-zircons .parameter-list__item .h4 {
    text-align: center;

    @include breakpoint(min-768) {
        text-align: left;
    }
}

.lp-jewelry-guide--diamonds-zircons .parameter-list__item .icon {
    fill: var(--gold-40);
    width: rem(24);
    height: rem(24);
    flex: 0 0 auto;
}

.lp-jewelry-guide--diamonds-zircons .table-wrapper {
    overflow-x: auto;
    margin: var(--space-10) auto;

    @include breakpoint(min-768) {
        margin: var(--space-20) auto;
    }
}


.lp-jewelry-guide--diamonds-zircons .table {
    width: 100%;
    max-width: rem(1080);
    margin: 0 auto;
}


.lp-jewelry-guide--diamonds-zircons .table thead tr th {
    background-color: var(--gray-10);
    padding: var(--space-4);
    font-size: var(--font-size-1);
    line-height: rem(18);
    text-transform: uppercase;
    letter-spacing: 1.5px;
    color: var(--gray-50);
    text-align: left;
}

.lp-jewelry-guide--diamonds-zircons .table tbody tr td {
    padding: var(--space-6) var(--space-4);
    font-size: var(--font-size-2);
    line-height: rem(22);
}

.lp-jewelry-guide--diamonds-zircons .mohs-image {
    width: 100%;
    max-width: rem(1128);
    margin: 0 auto;
    display: block;
}

.lp-jewelry-guide--diamonds-zircons .mohs-image img {
    width: 100%;
    height: auto;
}