/* ===========================
Advantages stripe - component
=========================== */
.c-advantages-stripe {
    background-color: var(--gray-05);
}

.c-advantages-stripe__list {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    margin: 0;
    padding: 0;

    @include breakpoint(min-640) {
        padding: var(--space-2) 0;
        grid-template-columns: repeat(2, 1fr);
    }

    @include breakpoint(min-1280) {
        padding: var(--space-4) 0;
        grid-template-columns: repeat(4, 1fr);
    }
}

.c-advantages-stripe__item {
    display: flex;
    align-items: center;
    gap: var(--space-1);
    margin: 0;
    padding: var(--space-4) var(--space-4) var(--space-4) 0;
    border-bottom: rem(1) solid var(--gray-20);


    @include breakpoint(min-640) {
        justify-content: center;
    }

    @include breakpoint(min-1280) {
        padding: 0 var(--space-4);
        border-right: rem(1) solid var(--gray-20);
        border-bottom: none;
    }

    &:nth-child(odd) {
        @include breakpoint(min-640) {
            border-right: rem(1) solid var(--gray-20);
        }
    }   

    &:nth-last-child(-n+2) {
        @include breakpoint(min-640) {
            border-bottom: none;
        }
    }

    &:last-child {
        border-bottom: none;

        @include breakpoint(min-1280) {
             border-right: none;
        }
    }
}

.c-advantages-stripe__item p {
    margin: 0;
    color: var(--gray-90);
    font-size: var(--font-size-3);
    line-height: rem(22);
}

.c-advantages-stripe__item a {
    font-weight: var(--font-regular);
}

.c-advantages-stripe .icon {
    width: rem(40);
    height: rem(40);
    fill: var(--gray-50);
    padding: var(--space-2);
}