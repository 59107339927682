/* ===========================
Jewelry guide page
=========================== */
.jewelry-guide-page {

    > .page-wrap {
        position: absolute;
        left: 0;
        right: 0;
        margin: auto;
        z-index: $z-index-5;
    }

    .jewelry-guide-cover {
        
        &__image {
            min-height: rem(560);
            background: var(--white);
            overflow: hidden;
            
            picture {
                position: relative;
                padding-bottom: rem(280);
                width: 100%;
                height: 100%;
                display: block;
            }

            img {
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                position: absolute;
                width: 100%;
                height: 100%;
                object-fit: cover;
            }

            figcaption {
                position: absolute;
                top: 0;
                left: 0;
                bottom: 0;
                right: 0;
                padding: rem(320) 0; /* TODO Fix this fixed padding */

                @include breakpoint(min-768)    { padding: rem(120) 0; }
                @include breakpoint(min-1600)   { padding: rem(160) 0; }
            
            }

            @include breakpoint(min-768)    { height: rem(480); }
            @include breakpoint(min-768)    { height: rem(480); }
            @include breakpoint(min-1280)   { height: rem(520); }
            @include breakpoint(min-1600)   { height: rem(600); }
        
        }

        &__heading {
            display: block;
            color: var(--gray-70); 
            // font-size: var(--font-size-9);
            // line-height: rem(36);
            margin-bottom: rem(12);
            margin: 0 auto rem(12) auto;
            font-family: $font-stack-display;
            font-weight: var(--font-weight-regular);
            text-align: center;

            @include breakpoint(min-768) {
                // font-size: var(--font-size-10);
                // line-height: rem(64);
                margin-bottom: rem(28);
            }

            @include breakpoint(min-1280) {
                max-width: 55ch;
                // font-size: var(--font-size-10);
                // line-height: rem(48);
                margin: 0 0 rem(24) 0;
                text-align: left;
            }

        }

        &__desc {
            color: var(--gray-70);
            // font-size: var(--font-size-4);
            // line-height: rem(24);
            text-align: center;
            max-width: 60ch;
            display: block;
            margin: 0 auto;
            letter-spacing: -0.6px;

            @include breakpoint(min-768) {
                // font-size: var(--font-size-6);
                // line-height: rem(28);
                margin-bottom: rem(40);
            }

            @include breakpoint(min-1280) {
                text-align: left;
                max-width: 55ch;
                // font-size: var(--font-size-5);
                // line-height: rem(28);
                margin: 0 0 rem(24) 0;
            }

        }
    }

    //Content section
    .jewelry-guide-content {
        padding: 0 0 rem(60) 0;
        background-color: var(--gray-10);

        @include breakpoint(min-1024) {
            padding: rem(64) 0 rem(80) 0;
        }

        > .page-wrap {
            width: 100%;

            @include breakpoint(min-1024) {
                width: 96%;
            }
        }
    }

    .l-jewelry-guide-flex-wrapper {

        @include breakpoint(min-1024) {
            display: flex;
        }
    }

    //Left menu
    .jewelry-guide-aside-menu-container {
        background-color: var(--gray-10);
        padding: rem(24) 2% rem(8) 2%;
        margin: 0 auto rem(40) auto;
        width: 100%;
        flex: 0 0 100%;

        @include breakpoint(min-1024) {
            padding: 0 rem(36) 0 0;
            margin: 0;
            flex: 0 0 rem(300);
        }

        &__heading {
            font-family: $font-stack-display;
            font-weight: var(--font-weight-regular);
            margin: 0 0 rem(20) 0;

            @include breakpoint(min-1024) {
                display: none;
            }
        }

        &__block-heading {
            width: 100%;
            border: none;
            cursor: pointer;
            position: relative;
            display: inline-block;
            background-color: transparent;
            color: var(--gray-90);
            font-size: var(--font-size-4);
            font-weight: var(--font-weight-semibold);
            text-transform: uppercase;
            margin: 0;
            padding: 0;
            text-align: left;

            span {
                position: relative;
                width: 100%;
                display: block;
            }

            @include breakpoint(min-1024) {
                margin: 0 0 rem(18) 0;
                cursor: auto;
                pointer-events: none;
            }

            .icon-chevron-bottom {
                position: absolute;
                width: rem(8);
                height: rem(8);
                right: 0;
                top: 50%;
                pointer-events: none;
                fill: var(--gray-50);
                transform: translateY(-50%);

                @include breakpoint(min-1024) {
                    display: none;
                }
            }
        }

        &__block-list {
            margin: rem(24) 0 0 0;
            padding: 0;
            list-style-type: none;

            @include breakpoint(min-1024) {
                display: block;
                margin: 0;
                padding: 0 0 0 rem(20);
            }

            li {
                padding-left: 0;
                display: flex;
                align-items: center;
                margin-bottom: rem(12);

                &:last-of-type {
                    margin-bottom: 0;
                }

                @include breakpoint(min-1024) {
                    padding-left: rem(24);
                }

                &::before {
                    display: none;
                    content: "";
                    position: absolute;
                    left: 0;
                    top: 50%;
                    background-color: var(--gray-40);
                    width: rem(8);
                    height: rem(2);
                    transform: translateY(-50%);

                    @include breakpoint(min-1024) {
                        display: block;
                    }
                }
            }

            a {
                text-decoration: none;
                font-size: var(--font-size-3);
                line-height: rem(24);
                position: relative;
                transition: box-shadow .2s $ease-in-out-quad;
                color: var(--gray-90);

                &:hover {
                    box-shadow: 0 rem(2) 0 0 var(--gray-90);
                }

                &:hover .icon-arrow-right {
                    opacity: 1;
                }
            }

            .icon-arrow-right {
                position: absolute;
                top: rem(10);
                right: - rem(24);
                display: block;
                width: rem(12);
                height: rem(12);
                opacity: 0;
                transition: opacity .2s $ease-in-out-quad;
            }
        }
    }

    .l-jewelry-guide-aside-block-wrapper {
        border-radius: rem(3);
        margin: 0 0 rem(4) 0;
        padding: rem(20) rem(16);
        background-color: var(--white);

        &:last-of-type {
            margin: 0;
        }

        @include breakpoint(min-1024) {
            border-radius: 0;
            margin: 0 0 rem(36) 0;
            padding: 0;
            background-color: var(--gray-10);
        }
    }

    //Recommended list
    .jewelry-guide-recommended-container {
        width: 96%;
        margin: 0 auto;
        background-color: var(--white);

        img {
            max-width: 100%;
            display: block;
            height: auto;
        }

        @include breakpoint(min-1024) {
            padding: 0 0 0 rem(48);
            width: 100%;
        }

        &__heading {
            font-family: $font-stack-display;
            font-weight: var(--font-weight-regular);

            @include breakpoint(min-1024) {
                font-size: var(--font-size-9);
                line-height: rem(52);
                margin-bottom: rem(38);
            }
        }

        &__list {
            margin: 0;
            padding: 0;
            list-style-type: none;

            li {
                background-color: var(--gray-10);
                margin-bottom: rem(10);

                &:last-of-type {
                    margin-bottom: 0;
                }
            }

            a {
                text-decoration: none;

                &:hover {

                    .jewelry-guide-recommended-item__image {

                        img {
                            transform: scale(1.05);
                        }
                    }

                    .jewelry-guide-recommended-item__link {
                        box-shadow: 0 rem(2) 0 0 var(--gray-90);
                    }

                    .icon-arrow-right {
                        transform: translateX(rem(6));
                    }
                }
            }
        }
    }

    .jewelry-guide-recommended-item {
        padding: rem(20);
        display: flex;
        align-items: center;

        @include breakpoint(min-1280) {
            padding: rem(40);
        }

        &__image {
            overflow: hidden;
            width: rem(299);
            margin-right: rem(40);

            @include breakpoint(min-1024) {
                max-width: rem(299);
                max-height: rem(216);
                margin-right: rem(40);
                display: flex;
                align-items: center;
            }

            img {
                width: 100%;
                transform: translate3d(0,0,0);
                transition: transform .2s $ease-in-out-quad;
            }
        }

        &__heading {
            color: var(--gray-90);
            font-size: var(--font-size-5);
            line-height: rem(20);
            text-transform: uppercase;
            margin-bottom: rem(18);
        }

        &__desc {
            width: 100%;
            max-width: rem(560);
            color: var(--gray-70);
            font-size: var(--font-size-4);
            line-height: rem(24);
            letter-spacing: -.53px;
            margin-bottom: rem(32);
        }

        &__link  {
            display: table;
            margin: 0;
            color: var(--gray-90);
            box-shadow: 0 rem(1) 0 0 var(--gray-90);
            text-decoration: none;
            font-size: var(--font-size-3);
            line-height: rem(28);
            text-transform: uppercase;
            font-weight: var(--font-weight-semibold);
            position: relative;
            transition: box-shadow .2s $ease-in-out-quad;
        }

        .icon-arrow-right {
            position: absolute;
            top: rem(8);
            right: - rem(16);
            display: block;
            width: rem(12);
            height: rem(12);
            transition: transform .2s $ease-in-out-quad;
        }
    }

    //Common wrapper for section
    .l-jewelry-guide-section-wrapper {
        display: flex;
        align-items: center;
    }

    h2 {
        margin: rem(52) 0 rem(32) 0;

        &:first-of-type {
            margin: 0 0 rem(32) 0;
        }

        strong {
            font-weight: var(--font-weight-semibold);
        }
    }

    .items-list {
        list-style-type: none;
        padding: 0;
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;
        margin: -.5rem -.5rem 5rem;
        justify-content: center;

        .products__item {
            flex-basis: calc(25% - 1rem);
        }

    }

    .cms-button {
        background: red;
        text-align: center;
        display: inline-block;
        font-weight: var(--font-weight-semibold);
        text-transform: uppercase;
        text-decoration: none;
        transition: background-color .2s $ease-in-out-quad, box-shadow .2s $ease-in-out-quad, color .2s $ease-in-out-quad;
        background-color: var(--white);
        color: var(--gray-90);
        border: rem(2) solid var(--gray-90);
        font-size: var(--font-size-2);
        line-height: rem(40);
        padding: 0 rem(20);
        margin: 0 rem(8) rem(8) 0;

        &:hover {
            background-color: var(--gray-90);
            color: var(--white);
            box-shadow: 0 0 0 rem(2) var(--gray-90);
        }
    }

    img {
        // max-width: 100%;
    }

    .box-articles {
        background-color: #f5f5f5;
        margin-bottom: .625rem;
        position: relative;
        padding: 2.5rem;
        
        @include breakpoint(min-640) {
            padding: 2.5rem 2.5rem 2.5rem 25rem;
            height: rem(300);
        }
        
        p {
            overflow: hidden;
            max-height: rem(140);
        }

        > a:first-of-type {
            display: block;
            margin-bottom: rem(30);
            
            @include breakpoint(min-640) {
                position: absolute;
                left: 2.5rem;
            }


            
        }

        h2 {
            color: #1a1919;
            font-size: 1.125rem;
            line-height: 1.25rem;
            text-transform: uppercase;
            margin-bottom: 1.125rem;
        }
    }

   
}

.about-box {
    padding: rem(32) 0 rem(44) 0;

    img {
        margin-bottom: rem(32);
        display: block;
    }
}

//Spacing for about jewel article list component
.o-page-section--about-section .l-lp-article-list {
    margin-top: var(--space-6);

    @include breakpoint(min-768) {
        margin-top: var(--space-10);
    }

    @include breakpoint(min-1280) {
        margin-top: var(--space-20);
    }
}
