/* ===========================
Custom jewelry page
=========================== */
.custom-jewelry-page {
    //Header section
    .custom-jewelry-cover {

        &__image {
            display: flex;
            align-items: center;
            min-height: rem(360);
            background: var(--white);
            overflow: hidden;

            @include breakpoint(min-640) {
                min-height: rem(400);
            }

            @include breakpoint(min-640) {
                min-height: rem(400);
            }

            @include breakpoint(min-960) {
                min-height: rem(480);
            }

            picture {
                overflow: hidden;
                position: absolute;
                height: 100%;
                display: block;
                left: 0;
                top: 0;
                width: 100%;
            }

            img {
                position: absolute;
                height: auto;
                min-height: 100%;
                min-width: 100%;
                left: 50%;
                transform: translateX(-50%);
            }

            figcaption {
                z-index: $z-index-1;
                text-align: center;
                padding: rem(24) 0;

                @include breakpoint(min-960) {
                    text-align: left;
                }
            }

            @include breakpoint(min-1280) {
                height: rem(436);
            }
        }

        &__heading {
            font-size: var(--font-size-9);
            line-height: rem(44);
            max-width: rem(593);
            font-family: $font-stack-display;
            font-weight: var(--font-weight-regular);
            margin: 0 auto rem(16) auto;

            @include breakpoint(min-768) {
                font-size: var(--font-size-10);
                line-height: rem(52);
                margin: 0 auto rem(24) auto;
            }
            @include breakpoint(min-960) {
                margin: 0 auto rem(24) 0;
            }
        }

        &__desc {
            color: var(--gray-70);
            font-size: var(--font-size-4);
            line-height: rem(24);
            letter-spacing: -0.6px;
            margin: 0 auto rem(24) auto;
            padding: 0;
            max-width: rem(593);

            @include breakpoint(min-768) {
                font-size: var(--font-size-5);
                line-height: rem(28);
            }

            @include breakpoint(min-960) {
                margin: 0 auto rem(24) 0;
            }

            a {
                color: var(--gray-90);
                font-weight: var(--font-weight-semibold);

                &:hover {
                    text-decoration: none;
                }
            }
        }

        &__btn {
            max-width: rem(240);
            margin: 0 auto;

            @include breakpoint(min-960) {
                 margin: 0;
            }
        }
    }

    //Manufacturing process section
    .custom-jewelry-manufacturing-process {
        padding: rem(32) 0;
        text-align: center;

        @include breakpoint(min-480) {
            padding: rem(40) 0;
        }

        @include breakpoint(min-768) {
            padding: rem(60) 0;
        }

        @include breakpoint(min-1280) {
            padding: rem(80) 0;
        }

        .l-custom-jewelry-section-wrapper {

            @include breakpoint(min-640) {
                flex-direction: column;
            }

            @include breakpoint(min-960) {
                flex-direction: row;
            }
        }

        &__image {
            max-width: rem(344);
            width: 100%;
            order: 1;

            img {
                width: 100%;
            }

            @include breakpoint(min-640) {
                max-width: rem(480);
            }

            @include breakpoint(min-768) {
                max-width: rem(560);
            }

            @include breakpoint(min-960) {
                margin-right: rem(40);
                margin-bottom: 0;
                order: 0;
                max-width: rem(660);
            }
        }

        &__text {
            text-align: center;
            margin: 0 auto rem(24) auto;

            @include breakpoint(min-640) {
                margin: 0 auto rem(32) auto;
            }

            @include breakpoint(min-768) {
                max-width: rem(660);
            }

            @include breakpoint(min-960) {
                text-align: left;
                margin: 0 auto;
            }

            h2 {
                font-family: $font-stack-display;
                font-weight: var(--font-weight-regular);
                margin-bottom: rem(20);

                small {
                    color: var(--gray-50);
                    font-family: $font-stack-text;
                    font-size: var(--font-size-3);
                    line-height: rem(14);
                    font-weight: var(--font-weight-semibold);
                    text-transform: uppercase;
                    display: block;
                }

                @include breakpoint(min-640) {
                    font-size: var(--font-size-7);
                    line-height: rem(40);
                }

                @include breakpoint(min-768) {
                    font-size: var(--font-size-9);
                    line-height: rem(52);
                }
            }

            > p {
                color: var(--gray-70);
                font-size: var(--font-size-5);
                line-height: rem(28);
                letter-spacing: -0.6px;
                margin: 0 auto rem(16) auto;
                padding: 0;
                max-width: rem(600);

                @include breakpoint(min-960) {
                    margin: 0 0 rem(32) 0;
                }
            }

            .custom-jewelry-process-list {
                margin: rem(60) 0 0 0;
                padding: 0;
                list-style-type: none;

                &__heading {
                    color: var(--gray-90);
                    font-size: var(--font-size-5);
                    line-height: rem(24);
                    text-transform: uppercase;
                    margin: 0 0 rem(4) 0;
                }

                &__desc {
                    font-size: var(--font-size-4);
                    line-height: rem(24);
                    max-width: rem(660);
                    margin: 0;
                }
            }

            .l-custom-jewelry-process-item {
                margin: 0 0 rem(40) 0;

                &:last-of-type {
                    margin: 0;
                }

                a {
                    color: var(--gray-90);
                    font-weight: var(--font-weight-semibold);

                    &:hover {
                        text-decoration: none;
                    }
                }
            }
        }
    }

    .custom-jewelry-3d-model {
        margin: rem(16) 0 0 0;
        padding: rem(32) 0;
        text-align: center;
        background-color: var(--gray-10);

        @include breakpoint(min-480) {
            margin: rem(24) 0 0 0;
            padding: rem(40) 0;
        }

        @include breakpoint(min-768) {
            margin: rem(32) 0 0 0;
            padding: rem(60) 0;
        }

        @include breakpoint(min-1280) {
            margin: rem(40) 0 0 0;
            padding: rem(80) 0;
        }
    }

    //Carousel
    .custom-jewelry-carousel {
        width: 100%;
        margin: 0 auto;
        padding: 0;
        list-style-type: none;
        overflow-y: hidden;
        overflow-x: auto;
        -ms-overflow-style: none; //Hide scrollbar for IE 10+
        overflow: -moz-scrollbars-none; //Hide scrollbar for Firefox
        display: flex;

        //Hide scrollbar for Safari and Chrome
        &::-webkit-scrollbar {
            display: none;
        }

        &::before {
            z-index: $z-index-1;
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            width: rem(60);
            height: rem(162);
            background-image: linear-gradient(to right, var(--gray-10), rgba(255,255,255,0));
        }

        &::after {
            z-index: $z-index-1;
            content: "";
            position: absolute;
            top: 0;
            right: 0;
            width: rem(60);
            height: rem(162);
            background-image: linear-gradient(to left, var(--gray-10), rgba(255,255,255,0));
        }

        &__item {
            display: block;
            background-color: var(--white);
            border: 1px solid var(--gray-20);
            margin-right: rem(10);
            height: 100%;

            &:last-of-type {
                margin-right: 0;
            }

            img {
                display: block;
            }
        }
    }

    .l-custom-jewelry-intro {
        padding: 0 0 rem(80) 0;

        .custom-jewelry-3d-model__text {
            margin: 0 auto;
            text-align: center;

            @include breakpoint(min-1280) {
                max-width: rem(640);
            }

            h2 {
                max-width: rem(660);
                margin: 0 auto rem(20) auto;
                font-family: $font-stack-display;
                font-weight: var(--font-weight-regular);

                small {
                    color: var(--gray-50);
                    font-family: $font-stack-text;
                    font-size: var(--font-size-3);
                    line-height: rem(14);
                    font-weight: var(--font-weight-semibold);
                    text-transform: uppercase;
                    display: block;
                }

                @include breakpoint(min-640) {
                    font-size: var(--font-size-7);
                    line-height: rem(40);
                }

                @include breakpoint(min-768) {
                    font-size: var(--font-size-9);
                    line-height: rem(52);
                }
            }

            p {
                max-width: rem(660);
                color: var(--gray-70);
                font-size: var(--font-size-5);
                line-height: rem(28);
                letter-spacing: -0.6px;
                margin: 0 auto rem(32) auto;

                @include breakpoint(min-960) {
                    margin: 0 auto rem(60) auto;
                }

                @include breakpoint(min-1280) {
                    margin: 0 auto rem(80) auto;
                }
            }

            .btn {
                margin: 0 auto;
                max-width: rem(240);
            }
        }
    }

    .l-custom-jewelry-carousel {
        position: relative;
        padding: 0 0 rem(62) 0;
    }

    .l-custom-jewelry-deposit {

        .custom-jewelry-3d-model__text {
            margin: 0 auto;
            text-align: center;
            max-width: rem(660);

            h3 {
                font-family: $font-stack-display;
                font-weight: var(--font-weight-regular);
                margin-bottom: rem(16);

                @include breakpoint(min-1280) {
                    font-size: var(--font-size-7);
                    line-height: rem(24);
                    margin-bottom: rem(16);
                }
            }

            p {
                margin: 0 auto;
                letter-spacing: -0.6px;
            }
        }
    }

    //Form section
    .custom-jewelry-form {
        padding: rem(60) 0;
        margin: 0 auto;

        @include breakpoint(min-1280) {
            padding: rem(80) rem(120);
        }

        &__text {
            margin: 0 auto;
            text-align: center;

            @include breakpoint(min-1280) {
                max-width: rem(640);
            }


            h2 {
                max-width: rem(660);
                margin: 0 auto rem(20) auto;
                font-family: $font-stack-display;
                font-weight: var(--font-weight-regular);

                small {
                    color: var(--gray-50);
                    font-family: $font-stack-text;
                    font-size: var(--font-size-3);
                    line-height: rem(14);
                    font-weight: var(--font-weight-semibold);
                    text-transform: uppercase;
                    display: block;
                }

                @include breakpoint(min-640) {
                    font-size: var(--font-size-7);
                    line-height: rem(40);
                }

                @include breakpoint(min-768) {
                    font-size: var(--font-size-9);
                    line-height: rem(52);
                }
            }

            p {
                max-width: rem(660);
                color: var(--gray-70);
                font-size: var(--font-size-5);
                line-height: rem(28);
                letter-spacing: -0.6px;
                margin: 0 auto;
            }
        }
    }

    .contact-form-container {
        margin: 0 auto;
        max-width: rem(526);
    }

    .contact-form {
        margin: 0;
        padding: 0;
        list-style-type: none;
        display: flex;
        flex-wrap: wrap;

        &__item {
            width: 100%;
            margin-bottom: rem(16);

            @include breakpoint(min-1280) {
                flex: 1 1 rem(258);
                max-width: rem(258);
            }

            &--name {
                @include breakpoint(min-1280) {
                    flex: 1 1 100%;
                    max-width: none;
                }
            }

            &--email {
                @include breakpoint(min-1280) {
                    margin-right: auto;
                }
            }

            &--phone {
                @include breakpoint(min-1280) {
                   margin-left: auto;
                }
            }

            &--message {
                margin-bottom: rem(40);

                @include breakpoint(min-1280) {
                    flex: 1 1 100%;
                    max-width: none;
                }
            }

            &--budget {
                @include breakpoint(min-1280) {
                    flex: 1 1 rem(180);
                    max-width: rem(180);
                }
            }

            &--sketch {
                @include breakpoint(min-1280) {
                    flex: 1 1 100%;
                    max-width: none;
                }
            }

            .textarea {
                height: rem(160);
            }

            &--btn {
                @include breakpoint(min-1280) {
                    flex: 1 1 rem(240);
                    max-width: rem(240);
                }

                .btn {
                    width: 100%;
                }
            }

            &--link {

                @include breakpoint(min-1280) {
                    margin-left: auto;
                    display: flex;
                    align-items: center;
                    justify-content: flex-end;
                }

                a {
                    color: var(--gray-50);
                    font-weight: var(--font-weight-semibold);

                    &:hover {
                        text-decoration: none;
                    }
                }
            }
        }
    }

    .l-form-customer-wrapper, .l-form-jewelry-wrapper {
        margin: 0 auto rem(44) auto;

        &:last-of-type {
            margin: 0 auto;
        }

        h3 {
            font-family: $font-stack-display;
            font-weight: var(--font-weight-regular);
            margin-bottom: rem(16);
            text-align: center;

            @include breakpoint(min-1280) {
                font-size: var(--font-size-7);
                line-height: rem(24);
                margin-bottom: rem(32);
            }
        }
    }

    //Common wrapper for section
    .l-custom-jewelry-section-wrapper {
        display: flex;
        align-items: center;
        flex-direction: column;

        @include breakpoint(min-640) {
            flex-direction: row;
        }
    }
}

