/* ===========================
Landing page border box item - component
=========================== */
.l-lp-border-box-list {
    width: 100%;
    display: grid;
    gap: var(--space-2);
    margin: var(--space-6) 0 0 0;
    padding: 0;
    list-style-type: none;

    @include breakpoint(min-768) {
        grid-template-columns: repeat(2,1fr);
        gap: var(--space-4);
        margin-top: var(--space-10);
    }

    @include breakpoint(min-1280) {
        grid-template-columns: repeat(4,1fr);
        gap: var(--space-6);
        margin-top: var(--space-20);
    }

    + .o-page-section__content {
        margin-top: var(--space-6);

        @include breakpoint(min-768) {
            margin-top: var(--space-8);
        }
    
        @include breakpoint(min-1280) {
            margin-top: var(--space-16);
        }
    }
}

//Color modifier
.l-lp-border-box-list--pink .lp-border-box-item {
    border-color: var(--pink-20);
}

.l-lp-border-box-list--gold .lp-border-box-item {
    border-color: var(--gold-20);
}

//Column count on desktop modifier
.l-lp-border-box-list--column-2 {
    @include breakpoint(min-1280) {
        grid-template-columns: repeat(2,1fr);
    }
}

.l-lp-border-box-list--column-3 {
    @include breakpoint(min-1280) {
        grid-template-columns: repeat(3,1fr);
    }
}

.l-lp-border-box-list--column-5 {
    @include breakpoint(min-1280) {
        grid-template-columns: repeat(5,1fr);
    }
}

.l-lp-border-box-list--grid-1 {
    display: grid;
    grid: repeat(4, 1fr); 
}

//Fullwidth image modifier
.l-lp-border-box-list--fullwidth-image .lp-border-box-item__image {
    max-width: none;
    width: calc(100% + #{rem(32)});

    @include breakpoint(min-768) {
        width: calc(100% + #{rem(48)});

    }
}

//Item component
.lp-border-box-item {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    border: var(--space-05) solid var(--gray-20);
    padding: var(--space-4);

    @include breakpoint(min-768) {
        padding: var(--space-6);
    }
}

.lp-border-box-item--large {
    grid-column: 1 / 3;
    grid-row: 1 / 4;
}


.lp-border-box-item a:focus {
    background-color: transparent;
}

.lp-border-box-item__image {
    max-width: 100%;
    height: auto;
    margin-bottom: var(--space-4);

    @include breakpoint(min-768) {
        margin-bottom: var(--space-6);
    }
}

.lp-border-box-item__sub-heading {
    display: block;
    color: var(--gray-40);
    font-size: var(--font-size-1);
    line-height: rem(18);
    text-transform: uppercase;
    letter-spacing: 1.5px;
    margin-bottom: var(--space-2);
}

.lp-border-box-item__heading {
    display: block;
    font-size: var( --font-size-6);
    line-height: rem(26);
    margin-bottom: var(--space-2);
   
    @include breakpoint(min-768) {
        font-size: var(--font-size-8);
        line-height: rem(42);
        margin-bottom: var(--space-4);
    }
}

.lp-border-box-item__desc {
    display: block;
    color: var(--gray-70);
    font-size: var(--font-size-3);
    line-height: rem(22);
    margin: 0 0 var(--space-6) 0;

    @include breakpoint(min-768) {
        font-size: var(--font-size-4);
        line-height: var( --line-height-3);
        margin-bottom: var(--space-10);
    }
}