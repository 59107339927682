/* ===========================
FAQ list
=========================== */

.faq-info-block {
    margin: 0 auto rem(32) auto;
    padding: 0;
    list-style-type: none;
    width: 100%;

    @include breakpoint(min-768) {
        margin: 0 auto rem(64) auto;
    }
}

.faq-info-block:last-of-type {
    margin: 0 auto;
}

.faq-info-block__item {
    background-color: var(--white);
    margin: 0 0 rem(8) 0;
    padding: rem(20) rem(24);

    @include breakpoint(min-768) {
        padding: rem(38) rem(40);
    }
}

.faq-info-block__item:last-of-type {
    margin: 0;
}

.faq-info-block__item button {
    background-color: transparent;
    position: relative;
    display: block;
    width: 100%;
    text-align: left;
    border: none;
}

.faq-info-block__item h3 {
    // font-size: var(--font-size-6);
    // line-height: rem(28);
    // font-family: $font-stack-display;
    // font-weight: var(--font-weight-regular);
    padding-right: rem(28);
    margin-bottom: 0;

    // @include breakpoint(min-768) {
    //     font-size: var(--font-size-7);
    //     line-height: rem(32);
    // }
}

.faq-info-block__item .icon-chevron-bottom {
    position: absolute;
    width: rem(12);
    height: rem(12);
    right: 0;
    top: 50%;
    transform: translateY(-50%);
}

.faq-info-block__item p {
    color: var(--gray-70);
    margin-top: rem(16);
}

.faq-info-block__item p:last-of-type {
    margin-bottom: 0;

    @include breakpoint(min-768) {
        margin-top: rem(24);
    }
}

.faq-info-block__item strong {
    color: var(--gray-90);
}

.faq-info-block .images-gallery {
    display: block;
    margin-top: rem(16);

    @include breakpoint(min-768) {
        margin-top: rem(24);
    }
}