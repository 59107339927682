/* ===========================
Rings engraving page
=========================== */
.lp-jewelry-guide--rings-engraving .engraving-options-list {
    width: 100%;
    display: inline-flex;
    flex-wrap: wrap;
    justify-content: center;
    margin: rem(36) rem(-4) rem(-4) rem(-4);
    padding: 0;
    list-style-type: none;

    @include breakpoint(min-768) {
        margin: rem(72) rem(-8) rem(-8) rem(-8);
    }
}

.lp-jewelry-guide--rings-engraving .engraving-options-list__item {
    overflow: hidden;
    margin: rem(4);

    @include breakpoint(min-640) {
        max-width: rem(360);
    }

    @include breakpoint(min-768) {
        margin: rem(8);
    }

    @include breakpoint(min-1024) {
        max-width: none;
        width: calc(33.33% - #{rem(16)});
    }
}

.lp-jewelry-guide--rings-engraving .engraving-options-list__item img {
    width: 100%;
    height: auto;
    margin-left: 50%;
    transform: translateX(-50%);
}

.lp-jewelry-guide--rings-engraving .engraving-options-list__item .o-page-section__heading-3, .lp-jewelry-guide--rings-engraving .engraving-options-list__item .o-page-section__desc-small {
    margin: 0 auto rem(16) auto;
    padding: 0 rem(40);
    text-align: center;
}

.lp-jewelry-guide--rings-engraving .engraving-options-list__item .o-page-section__heading-3:last-child, .lp-jewelry-guide--rings-engraving .engraving-options-list__item .o-page-section__desc-small:last-child {
    margin-bottom: 0;
    padding-bottom: rem(40);
}

.lp-jewelry-guide--rings-engraving .engraving-options-list__item--one {
    background-color: #F0F1F3;
}

.lp-jewelry-guide--rings-engraving .engraving-options-list__item--two {
    background-color: #F4F4F5;
}

.lp-jewelry-guide--rings-engraving .engraving-options-list__item--three {
    background-color: #F2F1F6;
}

.lp-jewelry-guide--rings-engraving .o-page-section--engraving-gallery {
    flex-wrap: wrap;
    justify-content: center;
    max-width: none;
    width: 100%;
    padding-left: 0;
    padding-right: 0;
}

.lp-jewelry-guide--rings-engraving .engraving-gallery-list {
    width: 100%;
    padding: 0;
    list-style-type: none;
    justify-content: center;
    display: flex;
    overflow: hidden;
    margin: rem(24) auto;
    text-align: center;

    @include breakpoint(min-768) {
        margin: rem(60) auto rem(32) auto;
    }

    @include breakpoint(min-1280) {
        margin: rem(80) auto rem(40) auto;
    }
}

.lp-jewelry-guide--rings-engraving .engraving-gallery-list__item {
    margin: rem(4);
}

.lp-jewelry-guide--rings-engraving .engraving-gallery-list__item img {
    width: rem(160);
    height: auto;

    @include breakpoint(min-640) {
        width: rem(240);
    }

    @include breakpoint(min-1280) {
        width: rem(300);
    }
}