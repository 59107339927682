/* ===========================
Investment diamonds page
=========================== */

// TODO fix 
.investment-diamonds-form__form {
    margin-top: rem(40);

    .contact-form__item--link {
        margin-bottom: rem(32);
    }
}

.investment-diamonds-page {
    .investment-diamonds-cover {

        &__image {
            height: rem(480);
            background: var(--white);
            overflow: hidden;
            display: flex;
            align-items: center;

            @include breakpoint(min-480) {
                height: rem(400);
            }

            @include breakpoint(min-768) {
                height: rem(560);
            }

            @include breakpoint(min-1280) {
                height: rem(660);
            }

            picture {
                position: relative;
                width: 100%;
                height: 100%;
                display: block;
            }

            img {
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                position: absolute;
                width: 100%;
                height: 100%;
                object-fit: cover;
            }

            figcaption {
                position: absolute;
                left: 0;
                right: 0;
                text-align: center;

                @include breakpoint(min-960) {
                    text-align: left;
                }
            }
        }

        &__heading {
            font-size: var(--font-size-9);
            line-height: rem(44);
            max-width: rem(593);
            font-family: $font-stack-display;
            font-weight: var(--font-weight-regular);
            margin: 0 auto rem(16) auto;

            @include breakpoint(min-768) {
                font-size: var(--font-size-10);
                line-height: rem(52);
                margin: 0 auto rem(24) auto;
            }
            @include breakpoint(min-960) {
                margin: 0 auto rem(24) 0;
            }
        }

        &__desc {
            color: var(--gray-70);
            font-size: var(--font-size-4);
            line-height: rem(24);
            letter-spacing: -0.6px;
            margin: 0 auto rem(16) auto;
            padding: 0;
            max-width: rem(593);

            @include breakpoint(min-768) {
                font-size: var(--font-size-5);
                line-height: rem(28);
                margin: 0 auto rem(16) auto;
            }

            @include breakpoint(min-960) {
                margin: 0 auto rem(16) 0;
            }

            &:first-of-type {
                color: var(--gray-80);
                font-family: $font-stack-display;
                font-weight: var(--font-weight-regular);

                @include breakpoint(min-768) {
                    font-size: var(--font-size-7);
                    line-height: rem(32);
                    letter-spacing: -.8;
                    margin-bottom: rem(55);
                }
            }

            &:last-of-type {
                margin: 0 auto;

                @include breakpoint(min-960) {
                    margin: 0;
                }
            }
        }
    }

    .investment-diamonds-advantages {
        padding: rem(32) 0;
        text-align: center;

        @include breakpoint(min-480) {
            padding: rem(40) 0;
        }

        @include breakpoint(min-768) {
            padding: rem(60) 0;
        }

        @include breakpoint(min-1280) {
            padding: rem(80) 0;
        }

        &__heading {
            font-family: $font-stack-display;
            font-weight: var(--font-weight-regular);
            margin-bottom: rem(24);

            @include breakpoint(min-640) {
                font-size: var(--font-size-7);
                line-height: rem(40);
                margin-bottom: rem(32);
            }

            @include breakpoint(min-768) {
                font-size: var(--font-size-9);
                line-height: rem(52);
                margin-bottom: rem(40);
            }

            @include breakpoint(min-960) {
                margin-bottom: rem(60);
            }
        }

        ul {
            margin: 0;
            padding: 0;
            list-style-type: none;
            counter-reset: adv-counter;

            @include breakpoint(min-640) {
                text-align: left;
            }

            li {
                border: 2px solid var(--gray-20);
                padding: rem(16) rem(24);
                margin: 0 auto rem(8) auto;

                @include breakpoint(min-640) {
                    padding: rem(16) rem(24) rem(16) rem(100);
                }

                @include breakpoint(min-768) {
                    max-width: rem(1062);
                    padding: rem(40) rem(60) rem(40) rem(160);
                }

                &:last-of-type {
                    margin: 0 auto;
                }

                &::before {
                    opacity: .25;
                    color: var(--gray-40);
                    font-family: $font-stack-display;
                    font-weight: var(--font-weight-regular);
                    font-size: var(--font-size-10);
                    line-height: rem(80);
                    content: counter(adv-counter)'.';
                    counter-increment: adv-counter;

                    @include breakpoint(min-640) {
                        position: absolute;
                        left: rem(32);
                        top: 50%;
                        transform: translateY(-50%);
                    }

                    @include breakpoint(min-768) {
                        left: rem(60);
                        font-size: rem(88);
                        line-height: rem(100);
                    }
                }

                h3 {
                    font-size: var(--font-size-6);
                    line-height: rem(28);
                    font-family: $font-stack-display;
                    font-weight: var(--font-weight-regular);
                    margin-bottom: rem(16);

                    @include breakpoint(min-768) {
                        font-size: var(--font-size-7);
                        line-height: rem(32);
                    }
                }

                p {
                    color: var(--gray-70);
                    font-size: var(--font-size-4);
                    line-height: rem(24);
                    letter-spacing: -0.6px;
                }
            }
        }
    }

    .investment-diamonds-types {
        padding: rem(32) 0;
        text-align: center;

        @include breakpoint(min-480) {
            padding: rem(40) 0;
        }

        @include breakpoint(min-768) {
            padding: rem(60) 0;
        }

        @include breakpoint(min-1280) {
            padding: rem(80) 0;
        }

        .l-investment-diamonds-section-wrapper {

            @include breakpoint(min-640) {
                flex-direction: column;
            }

            @include breakpoint(min-960) {
                flex-direction: row;
            }
        }

        &__text {
            text-align: center;

            @include breakpoint(min-768) {
                max-width: rem(660);
            }

            @include breakpoint(min-960) {
                text-align: left;
            }

            @include breakpoint(min-1280) {
                margin: 0 0 0 auto;
            }

            h2 {
                font-family: $font-stack-display;
                font-weight: var(--font-weight-regular);
                margin-bottom: rem(20);

                @include breakpoint(min-640) {
                    font-size: var(--font-size-7);
                    line-height: rem(40);
                }

                @include breakpoint(min-768) {
                    font-size: var(--font-size-9);
                    line-height: rem(52);
                }
            }

            p {
                color: var(--gray-70);
                font-size: var(--font-size-4);
                line-height: rem(28);
                letter-spacing: -0.6px;
                margin: 0 0 rem(16) 0;
                padding: 0;

                @include breakpoint(min-960) {
                    margin: 0 0 rem(32) 0;
                }

                &:first-of-type {
                    font-size: var(--font-size-5);
                }

                &:last-of-type {
                    margin: 0 0 rem(24) 0;

                    @include breakpoint(min-768) {
                        margin: 0 0 rem(40) 0;
                    }

                    @include breakpoint(min-960) {
                        margin: 0;
                    }
                }
            }
        }

        &__image {
            max-width: rem(344);
            width: 100%;
            order: 1;

            img {
                width: 100%;
            }

            @include breakpoint(min-640) {
                max-width: rem(480);
            }

            @include breakpoint(min-768) {
                max-width: rem(560);
            }

            @include breakpoint(min-960) {
                margin-right: rem(40);
                margin-bottom: 0;
                order: 0;
            }

            @include breakpoint(min-1280) {
                margin-right: rem(40);
                max-width: rem(660);
            }

            @include breakpoint(min-1600) {
                max-width: rem(796);
            }
        }
    }

    .investment-diamonds-price {
        padding: rem(32) 0;
        text-align: center;

        @include breakpoint(min-480) {
            padding: rem(40) 0;
        }

        @include breakpoint(min-768) {
            padding: rem(60) 0;
        }

        @include breakpoint(min-1280) {
            padding: rem(80) 0;
        }

        .l-investment-diamonds-section-wrapper {

            @include breakpoint(min-640) {
                flex-direction: column;
            }

            @include breakpoint(min-960) {
                flex-direction: row;
            }
        }

        &__text {
            text-align: center;
            margin-bottom: rem(40);

            @include breakpoint(min-768) {
                max-width: rem(660);
            }

            @include breakpoint(min-960) {
                text-align: left;
            }

            @include breakpoint(min-1280) {
                margin: 0 auto 0 0;
            }

            h2 {
                font-family: $font-stack-display;
                font-weight: var(--font-weight-regular);
                margin-bottom: rem(20);

                @include breakpoint(min-640) {
                    font-size: var(--font-size-7);
                    line-height: rem(40);
                }

                @include breakpoint(min-768) {
                    font-size: var(--font-size-9);
                    line-height: rem(52);
                }
            }

            p {
                color: var(--gray-70);
                font-size: var(--font-size-5);
                line-height: rem(28);
                letter-spacing: -0.6px;
                margin: 0 0 rem(24) 0;
                padding: 0;

                @include breakpoint(min-960) {
                    margin: 0 0 rem(40) 0;
                }
            }

            ul {
                margin: 0;
                padding: 0;
                list-style-type: none;

                li {
                    margin: 0 0 rem(40) 0;

                    @include breakpoint(min-768) {
                        margin: 0 0 rem(60) 0;
                    }


                    &:last-of-type {
                        margin: 0;
                    }

                    h3 {
                        font-size: var(--font-size-6);
                        line-height: rem(28);
                        font-family: $font-stack-display;
                        font-weight: var(--font-weight-regular);
                        margin-bottom: rem(16);

                        @include breakpoint(min-768) {
                            font-size: var(--font-size-7);
                            line-height: rem(32);
                        }
                    }

                    p {
                        color: var(--gray-70);
                        font-size: var(--font-size-4);
                        line-height: rem(24);
                        letter-spacing: -0.6px;
                        margin: 0;
                    }
                }
            }
        }

        &__4c-box {
            text-align: left;
            border: 2px solid var(--gray-20);
            background-color: rgba(var(--gray-10),.5);
            padding: rem(32) rem(24);
            max-width: rem(660);

            @include breakpoint(min-640) {
                padding: rem(48) rem(40);
            }

            @include breakpoint(min-960) {
                margin-left: rem(40);
                padding: rem(32) rem(24);
            }

            @include breakpoint(min-1280) {
                padding: rem(48) rem(40);
            }

            h3 {
                font-size: var(--font-size-6);
                line-height: rem(28);
                font-family: $font-stack-display;
                font-weight: var(--font-weight-regular);
                margin-bottom: rem(16);

                @include breakpoint(min-768) {
                    font-size: var(--font-size-7);
                    line-height: rem(32);
                }
            }

            p {
                color: var(--gray-70);
                font-size: var(--font-size-4);
                line-height: rem(24);
                letter-spacing: -0.6px;
                margin: 0 0 rem(24) 0;

                @include breakpoint(min-768) {
                    margin: 0 0 rem(32) 0;
                }
            }

            ul {
                margin: 0;
                padding: 0;
                list-style-type: none;
                counter-reset: adv-counter;

                li {
                    padding: 0 0 0 rem(32);
                    margin: 0 0 rem(32) 0;

                    @include breakpoint(min-640) {
                        padding: 0 0 0 rem(56);
                    }

                    @include breakpoint(min-960) {
                        padding: 0 0 0 rem(32);
                    }

                    @include breakpoint(min-1280) {
                        padding: 0 0 0 rem(56);
                    }

                    &::before {
                        text-align: left;
                        opacity: .25;
                        color: var(--gray-40);
                        font-family: $font-stack-display;
                        font-weight: var(--font-weight-regular);
                        font-size: var(--font-size-8);
                        position: absolute;
                        top: rem(-10);
                        left: 0;
                        content: counter(adv-counter)'.';
                        counter-increment: adv-counter;

                        @include breakpoint(min-640) {
                            font-size: var(--font-size-10);
                        }

                        @include breakpoint(min-960) {
                            font-size: var(--font-size-8);
                        }

                        @include breakpoint(min-1280) {
                            font-size: var(--font-size-10);
                        }
                    }

                    h4 {
                        font-size: var(--font-size-5);
                        line-height: rem(20);
                        margin-bottom: rem(6);
                    }

                    p {
                        color: var(--gray-70);
                        font-size: var(--font-size-4);
                        line-height: rem(24);
                        letter-spacing: -0.6px;
                        margin: 0;
                    }
                }
            }

            .link-arrow {
                display: table;
                margin: 0 auto 0 rem(24);
                color: var(--gray-90);
                box-shadow: 0 rem(1) 0 0 var(--gray-90);
                text-decoration: none;
                font-size: var(--font-size-3);
                line-height: rem(28);
                text-transform: uppercase;
                font-weight: var(--font-weight-semibold);
                position: relative;
                transition: box-shadow .2s $ease-in-out-quad;

                &:hover {
                    box-shadow: 0 rem(2) 0 0 var(--gray-90);
                }

                &:hover .icon-arrow-right {
                    transform: translateX(rem(6));
                }

                @include breakpoint(min-640) {
                    margin-left: rem(56);
                }

                @include breakpoint(min-960) {
                    margin-left: rem(32);
                }

                @include breakpoint(min-1280) {
                    margin-left: rem(56);
                }

            }

            .icon-arrow-right {
                position: absolute;
                top: rem(8);
                right: rem(-16);
                display: block;
                width: rem(12);
                height: rem(12);
                transition: transform .2s $ease-in-out-quad;
            }
        }
    }

    .investment-diamonds-form {
        margin: rem(16) 0 0 0;
        padding: rem(32) 0;
        text-align: center;
        background-color: var(--gray-10);

        @include breakpoint(min-480) {
            margin: rem(24) 0 0 0;
            padding: rem(40) 0;
        }

        @include breakpoint(min-768) {
            margin: rem(32) 0 0 0;
            padding: rem(60) 0;
        }

        @include breakpoint(min-1280) {
            margin: rem(40) 0 0 0;
            padding: rem(80) 0;
        }

        &__heading {
            max-width: rem(660);
            margin: 0 auto rem(20) auto;
            font-family: $font-stack-display;
            font-weight: var(--font-weight-regular);

            @include breakpoint(min-640) {
                font-size: var(--font-size-7);
                line-height: rem(40);
            }

            @include breakpoint(min-768) {
                font-size: var(--font-size-9);
                line-height: rem(52);
            }
        }

        &__description {
            max-width: rem(660);
            color: var(--gray-70);
            font-size: var(--font-size-5);
            line-height: rem(28);
            letter-spacing: -0.6px;
            margin: 0 auto rem(32) auto;

            @include breakpoint(min-960) {
                margin: 0 auto rem(60) auto;
            }

            @include breakpoint(min-1280) {
                margin: 0 auto rem(80) auto;
            }
        }

        &__form {
            max-width: rem(526);
            margin: rem(20) auto 0 auto;

            h3 {
                font-size: var(--font-size-6);
                line-height: rem(28);
                font-family: $font-stack-display;
                font-weight: var(--font-weight-regular);
                margin-bottom: rem(28);

                @include breakpoint(min-768) {
                    font-size: var(--font-size-7);
                    line-height: rem(32);
                }
            }

            .contact-form {
                width: 100%;
                max-width: rem(526);
                margin: 0 auto;
                text-align: left;
                padding: 0;
                list-style-type: none;

                &__item {
                    width: 100%;
                    margin: 0 auto rem(16) auto;

                    &:last-of-type {
                        margin: 0 auto;
                    }

                    .textarea {
                        height: rem(160);
                    }

                    .input-container {
                        margin-top: rem(24);
                    }

                    .btn {
                        margin-top: rem(40);
                        width: 100%;
                        max-width: rem(240);
                    }
                }
            }
        }
    }

    //Common wrapper for section
    .l-investment-diamonds-section-wrapper {
        display: flex;
        align-items: center;
        flex-direction: column;

        @include breakpoint(min-640) {
            flex-direction: row;
        }
    }
}