/* ===========================
Punching ears page
=========================== */
.lp-jewelry-guide--punching-ears {

    .when-punching-image {
        @include breakpoint(min-1280) {
            max-width: rem(640);
            margin-left: auto;
        }
    }

    .o-page-section--detske-ousko {
        max-width: none;
        width: 100%;
        background-color: var(--pink-20);

        @include breakpoint(min-1280) {
            padding: rem(160) 0;
        }
    }

    .detske-ousko-image {
        mix-blend-mode: multiply;
        margin-bottom: var(--space-4);
    }
}