.c-product-card {
	display: flex;
	flex-direction: column;
	background-color: var(--white);
	padding: rem(8);
	width: 100%;
	position: relative;
	transition: background-color $ease-in-out-quad .2s;
	cursor: pointer;

	@include breakpoint(min-640) {
		padding: rem(12);
	}

	@include breakpoint(min-960) {
		padding: rem(16);
    }
    
    @include breakpoint(min-1200) {
		padding: rem(20);
	}
}
	
.c-product-card:hover {
	cursor: pointer;
	background-color: var(--gray-10);
}

.c-product-card:focus-within {
	text-decoration: underline;
	background-color: var(--gray-10);
	box-shadow: 0 0 0 rem(2) var(--gray-30);
}

.c-product-card__image-container {
	position: relative;
}

.c-product-card__image {
	display: block;
	width: 100%;
	height: auto;
	margin: 0 auto rem(12) auto;
	object-fit: cover;
	max-width: rem(260);
}

.c-product-card__favorites {
	position: absolute;
	top: - rem(8);
	right: - rem(8);
	width: rem(40);
	height: rem(40);
	padding: rem(12);
	background: var(--gray-10);
	border-radius: rem(32);
	transition: opacity $ease-in-out-quad .2s;
}

@media (any-hover: hover) {

	.c-product-card__favorites {
		opacity: 0;
	}
}

.c-product-card__favorites .icon {
	width: rem(16);
	height: rem(16);
	fill: var(--gray-70);
	transition: transform .2s $ease-in-out-quad, fill .2s $ease-in-out-quad;
}

.c-product-card__favorites:hover .icon,
.c-product-card__favorites:focus .icon {
	fill: var(--paradise-pink);
	transform: scale(1.2);
}

.c-product-card__badges {
    position: absolute;
    list-style-type: none;
    margin: - rem(1);
    padding: 0;
    display: flex;
    flex-direction: column;
	align-content: flex-start;
	bottom: rem(12);
	left: 0;
}

.c-product-card__badges--mobile {
	bottom: rem(6);
	flex-direction: row;
}

.c-product-card__badges li {
    margin: rem(1);
	align-self: flex-start;
}

.c-product-card__collection {
	text-transform: uppercase;
	font-weight: var(--font-weight-semibold);
	font-size: var(--font-size-2);
	line-height: rem(16);
	height: rem(16);
	color: var(--gray-60);
	opacity: 0;
	text-align: center;
	transition: opacity $ease-in-out-quad .2s;
	margin-bottom: rem(4);
	display: -webkit-box;
	-webkit-box-orient: vertical;
	-webkit-line-clamp: 1;
	overflow: hidden;
}

.c-product-card__name,
.l-order-process .c-product-card__name { // TODO REMOVE
	font-size: var(--font-size-3);
	line-height: rem(20);
    font-weight: var(--font-weight-regular);
    font-family: $font-stack-text;
	margin-bottom: rem(4);
	text-align: center;
	display: -webkit-box;
	-webkit-box-orient: vertical;
	-webkit-line-clamp: 2;
	overflow: hidden;
	
	a {
		color: var(--gray-70);
        text-decoration: none;
        font-weight: var(--font-weight-regular);
	}

	&:hover a {
		text-decoration: underline;
	}
}

.c-product-card__price {
	font-size: var(--font-size-3);
	font-weight: var(--font-weight-semibold);
	color: var(--gray-90);
	display: flex;
	list-style-type: none;
	margin: 0 0 rem(8) 0;
	padding: 0;
	text-align: center;
	justify-content: center;
}

.c-product-card__price li {
	margin: 0 rem(4);
}

.c-product-card__price .price {
	color: var(--paradise-pink);
}

.c-product-card__price .price:only-child {
	color: var(--gray-90);
}

.c-product-card__price .sale {
	text-decoration: line-through;
	color: var(--gray-60);
	font-weight: var(--font-weight-regular);
}

.c-product-card__config {
	list-style-type: none;
	display: flex;
	flex-direction: row;
	opacity: 1;
	margin: 0;
	padding: 0;
	justify-content: center;
}

.c-product-card__config-item {
	margin: 0;
	padding: rem(4)
}
	
.c-product-card__config-item .gold-color {
	width: rem(16);
	height: rem(16);
	border-radius: rem(16);
	display: block;
}
	
.c-product-card__config-item .gold-1 {
	background: linear-gradient(225deg, #B69563 -1.25%, #F1EADC 98.75%);
}
.c-product-card__config-item .gold-2 {
	background: linear-gradient(225deg, #ACACAC -1.25%, #F4F4F4 98.75%);
}
.c-product-card__config-item .gold-3,
.c-product-card__config-item .gold-4 {
	background: linear-gradient(225deg, #B58666 -1.25%, #EEE2D7 98.75%);
}

.c-product-card__config-item .gold-2-4 {
	background: linear-gradient( -45deg, var(--white-gold), var(--white-gold) 49%, var(--rose-gold) 49%, var(--rose-gold) 51%, var(--rose-gold) 51% ); 
}

.c-product-card__config-item .gold-1-2 {
	background: linear-gradient( -45deg, var(--white-gold), var(--white-gold) 49%, var(--yellow-gold) 49%, var(--yellow-gold) 51%, var(--yellow-gold) 51% ); 
}

.c-product-card:hover .c-product-card__collection,
.c-product-card:focus-within .c-product-card__collection,
.c-product-card:hover .c-product-card__favorites,
.c-product-card:focus-within .c-product-card__favorites {
	opacity: 1;
}

.c-product-card:hover .c-product-card__collection,
.c-product-card:focus-within .c-product-card__collection,
.c-product-card:hover .c-product-card__favorites,
.c-product-card:focus-within .c-product-card__favorites {
	opacity: 1;
}