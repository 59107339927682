/* ===========================
Jewels diamonds 3C page
=========================== */
.lp-jewelry-guide--jewels-diamonds-3c {
    .diamond-type-list {
        margin: rem(40) rem(-16) rem(-40) rem(-16);
        padding: 0;
        list-style-type: none;
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;
    }

    .diamond-type-list__item {
        margin: rem(40) rem(16);
        text-align: center;
        flex: 1 1 calc(50% - #{rem(32)});
        max-width: calc(50% - #{rem(32)});

        @include breakpoint(min-640) {
            flex: 1 1 calc(33.33% - #{rem(32)});
            max-width: calc(33.33% - #{rem(32)});
        }

        @include breakpoint(min-960) {
            flex: 1 1 calc(25% - #{rem(32)});
            max-width: calc(25% - #{rem(32)});
        }

        @include breakpoint(min-1280) {
            flex: 1 1 calc(20% - #{rem(32)});
            max-width: calc(20% - #{rem(32)});
        }
    }

    .diamond-type-list__item img {
        width: 100%;
        max-width: rem(240);
        height: auto;
        display: block;
        margin: 0 auto rem(24) auto;
        aspect-ratio: 240 / 264;
    }

    .diamond-type-list__item .o-page-section__heading-4 {
        text-align: center;
    }

    .diamond-cut-parameters {
        text-align: center;
        margin-top: rem(40);
    }

    .diamond-cut-parameters img {
        width: 100%;
        height: auto;
        display: block;
        margin: 0 auto;
        max-width: rem(792);
        aspect-ratio: 792 / 244;
    }

    .diamond-cut-parameters__list {
        text-align: center;
        margin: rem(60) rem(-16) rem(-16) rem(-16);
        padding: 0;
        list-style-type: none;
        display: flex;
        flex-wrap: wrap;
    }

    .diamond-cut-parameters__item {
        text-align: center;
        margin: rem(16);

        @include breakpoint(min-640) {
            flex: 1 1 calc(33.33% - #{rem(32)});
            max-width: calc(33.33% - #{rem(32)});
        }
    }

    .o-page-section--diamond-quality {
        background-color: var(--gray-10);
    }

    .diamond-quality-list, .diamond-quality-image-list {
        margin: rem(40) rem(-16) rem(-40) rem(-16);
        padding: 0;
        list-style-type: none;
        display: flex;
        flex-wrap: wrap;
    }

    .diamond-quality-list__item {
        margin: rem(40) rem(16);
        text-align: center;

        @include breakpoint(min-640) {
            flex: 1 1 calc(50% - #{rem(32)});
            max-width: calc(50% - #{rem(32)});
        }

        @include breakpoint(min-960) {
            flex: 1 1 calc(25% - #{rem(32)});
            max-width: calc(25% - #{rem(32)});
        }

        @include breakpoint(min-1600) {
            flex: 1 1 calc(20% - #{rem(32)});
            max-width: calc(20% - #{rem(32)});
        }
    }

    .diamond-cut-parameters__item .o-page-section__heading-3,
    .diamond-cut-parameters__item .o-page-section__desc-small,
    .diamond-quality-list__item .o-page-section__heading-3,
    .diamond-quality-list__item .o-page-section__desc-small,
    .diamond-quality-image-list__item .o-page-section__heading-4 {
        text-align: center;
    }

    .diamond-quality-image-list__item .o-page-section__heading-4 {
        margin-top: auto;
    }

    .diamond-quality-image-list__item {
        display: flex;
        flex-direction: column;
        margin: rem(40) rem(16);
        text-align: center;
        flex: 1 1 100%;

        @include breakpoint(min-640) {
            flex: 1 1 calc(33.33% - #{rem(32)});
            max-width: calc(33.33% - #{rem(32)});
        }
    }

    .diamond-quality-image-list__item img {
        max-width: 100%;
        height: auto;
        display: block;
        margin: 0 auto rem(32) auto;
    }
}