/* ===========================
Signpost page for UK
=========================== */

.country-select {
    min-height: 100%;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
}

.country-select__image-bg {
    position: fixed;
    top: 0;
    height: 100%;
    width: 100%;
    object-fit: cover;
}

.country-select__content-box {
    position: relative;
    width: 100%;
    max-width: rem(1200);
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    background-color: #FFFFFF;
    padding: 32px 16px 16px 16px;
    box-shadow: 0 0 20px rgba(57,54,52,.4);
    margin: 16px;
}

.country-select__content-box-header {
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 16px;
    max-width: 670px;
    text-align: center;
}

.country-select__content-box svg {
    width: auto;
    height: 32px;
}

.country-select__content-box h1 {
    font-family: 'Canela';
    font-style: normal;
    font-weight: 300;
    font-size: 32px;
    line-height: 42px;
    color: #1C1B1A;
    margin: 0;
}

.country-select__content-box p {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #504C49;
    margin: 0;
}

.country-select__country-list {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 8px;
    margin: 40px 0 0 0;
    padding: 0;
    list-style-type: none;
}

.country-select__country-item {
    width: 100%;
}

.country-select__country-item a {
    display: flex;
    align-items: center;
    gap: 16px;
    background-color: #F9F7F5;
    padding: 24px;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: #504C49;
    text-decoration: none;
    transition: background-color .2s ease-out;
}

.country-select__country-item a:hover, .country-select__country-item a:focus {
    background-color: #E5E1DB;
}

.country-select__country-item a svg {
    width: 36px;
    height: 36px;
}

@media only screen and (min-width: 768px) and (min-width: 640px) {
    .country-select__content-box {
        padding: 40px;
    }

    .country-select__content-box svg {
        height: 44px;
    }

    .country-select__content-box h1 {
        font-size: 40px;
        line-height: 52px;
    }

    .country-select__content-box p {
        font-size: 18px;
        line-height: 28px;
    }

    .country-select__country-list {
        flex-direction: row;
    }

    .country-select__country-item a {
        justify-content: center;
        flex-direction: column;
    }

}

@media only screen and (min-width: 768px) and (min-width: 1200px) {
    .country-select__content-box {
        padding: 80px;
    }

    .country-select__country-list {
        gap: 16px;
    }
}