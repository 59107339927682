/* ===========================
Config section - component
=========================== */
.c-config-section {
    overflow: hidden;
    margin: 0;
    display: flex;
    flex-wrap: wrap;
    background-color: var(--white);
    padding: rem(440) 0 var(--space-8) 0;

    @include breakpoint(min-768) {
        min-height: rem(560);
        padding: 0;
    }

    @include breakpoint(min-1280) {
        min-height: rem(800);
    }
}

//page-wrap
.c-config-section .page-wrap {
    display: inline-flex;
    align-items: center;
    z-index: 1;
}

.c-config-section picture {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
}

.c-config-section img {
    width: auto;
    height: auto;
    min-width: 100%;
    min-height: 100%;
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);


    @include breakpoint(min-480) {
        bottom: 50%;
        transform: translate(-50%, 47%);
    }

    @include breakpoint(min-640) {
        bottom: 0;
        transform: translateX(-50%);
    }

    @include breakpoint(min-768) {
        left: 0;
        transform: translate(0);
    }

    @include breakpoint(min-1280) {
        left: auto;
        right: 0;
    }
}

.c-config-section__content {
    text-align: center;
    margin: 0 auto;
    max-width: rem(640);

    @include breakpoint(min-768) {
        text-align: left;
        margin: 0;
    }
}

.c-config-section__heading {
    display: block;
    margin-bottom: var(--space-2);
   
    @include breakpoint(min-768) {
        margin-bottom: var(--space-4);
    }
}

.c-config-section__desc {
    display: block;
    color: var(--gray-70);
    margin: 0 0 var(--space-8) 0;

    @include breakpoint(min-768) {
        margin: 0 0 var(--space-10) 0;
    }

    @include breakpoint(min-1280) {
        margin: 0 0 var(--space-16) 0;
    }
}