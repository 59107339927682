/* ===========================
How to wear engagement ring page
=========================== */
.lp-jewelry-guide--engagement-ring-wearing .ring-wearing-list {
    width: 100%;
    display: inline-flex;
    flex-wrap: wrap;
    margin: rem(36) rem(-4);
    padding: 0;
    list-style-type: none;

    @include breakpoint(min-1280) {
        flex-wrap: nowrap;
        margin: rem(72) rem(-8);
    }
}

.lp-jewelry-guide--engagement-ring-wearing .ring-wearing-list__item {
    overflow: hidden;
    background-color: #E1E0E6;
    text-align: center;
    width: 100%;
    margin: rem(4);
    padding: rem(24) 0 0 0;
    display: inline-flex;
    flex-wrap: wrap;

    @include breakpoint(min-640) {
        padding: rem(40) 0 0 0;
    }

    @include breakpoint(min-960) {
        text-align: left;
        margin: rem(8);
        padding: rem(60) rem(340) rem(60) rem(32);
    }

    @include breakpoint(min-1280) {
        min-height: rem(760);
        text-align: center;
        width: calc(33.33% - #{rem(24)});
        margin: rem(12);
        padding: rem(40) rem(40) rem(480) rem(40);
    }
}

.lp-jewelry-guide--engagement-ring-wearing .ring-wearing-list__item .number {
    z-index: $z-index-1;
    width: 100%;
    font-family: $font-stack-display;
    color: var(--gold-50, #AE906F);
    font-size: var(--font-size-9);
    text-align: center;
    margin: 0 auto rem(8) auto;
    padding: 0 rem(24);

    @include breakpoint(min-640) {
        padding: 0 rem(40);
    }

    @include breakpoint(min-960) {
        font-size: var(--font-size-10);
        text-align: left;
        margin: 0 0  rem(8) 0;
        padding: 0;
    }

    @include breakpoint(min-1280) {
        font-size: var(--font-size-10);
        text-align: center;
        margin: 0 auto rem(8) auto;
    }
}

.lp-jewelry-guide--engagement-ring-wearing .ring-wearing-list__item .o-page-section__heading-3 {
    z-index: $z-index-1;
    text-align: center;
    margin: 0 auto rem(8) auto;
    padding: 0 rem(24);

    @include breakpoint(min-640) {
        padding: 0 rem(40);
    }

    @include breakpoint(min-960) {
        text-align: left;
        margin: 0 0  rem(8) 0;
        padding: 0;
    }

    @include breakpoint(min-1280) {
        text-align: center;
        margin: 0 auto rem(16) auto;
    }
}

.lp-jewelry-guide--engagement-ring-wearing .ring-wearing-list__item .o-page-section__desc-small {
    z-index: $z-index-1;
    text-align: center;
    margin: 0 auto;
    padding: 0 rem(24);

    @include breakpoint(min-640) {
        padding: 0 rem(40);
    }

    @include breakpoint(min-960) {
        text-align: left;
        margin: 0;
        padding: 0;
    }

    @include breakpoint(min-1280) {
        text-align: center;
        margin: 0 auto;
    }
}

.lp-jewelry-guide--engagement-ring-wearing .ring-wearing-list__item picture {
    width: 100%;

    @include breakpoint(min-960) {
        position: absolute;
        min-width: auto;
        margin-left: 0;
        transform: translateX(0);
        width: rem(517);
        max-height: 100%;
        right: 0;
        left: auto;
        bottom: 0;
    }

    @include breakpoint(min-1280) {
        min-width: rem(517);
        max-height: none;
        margin-left: 50%;
        transform: translateX(-50%);
        width: 100%;
        right: auto;
        left: 0;
    }
}

.lp-jewelry-guide--engagement-ring-wearing .ring-wearing-list__item img {
    display: block;
    width: 100%;
    height: auto;
}

.lp-jewelry-guide--engagement-ring-wearing .o-page-section--during {
    position: relative;
    background-color: #F4F4F5;
    overflow: hidden;
    margin: rem(24) 0;

    @include breakpoint(min-640) {
        margin: rem(40) 0 0 0;
    }

    @include breakpoint(min-768) {
        margin: rem(60) 0 0 0;
    }

    @include breakpoint(min-1600) {
        margin: rem(80) 0 0 0;
    }
}

.lp-jewelry-guide--engagement-ring-wearing .o-page-section--during .o-page-section {
    z-index: 1;
    overflow: visible;
    padding-bottom: 0;

    @include breakpoint(min-1280) {
        height: rem(800);
    }
}

.lp-jewelry-guide--engagement-ring-wearing .o-page-section--during .o-page-section__image {
    display: none;

    @include breakpoint(min-1280) {
        display: block;
    }
}

.lp-jewelry-guide--engagement-ring-wearing .o-page-section--during picture img {
    width: 100%;
    height: auto;

    @include breakpoint(min-1280) {
        top: 0;
        position: absolute;
        width: auto;
        height: rem(800);
        right: 0;
    }
}

.lp-jewelry-guide--engagement-ring-wearing .o-page-section--after {
    padding-top: rem(80);

    @include breakpoint(min-768) {
        padding-top: rem(100);
    }

    @include breakpoint(min-1280) {
        padding-top: rem(160);
    }
}

.lp-jewelry-guide--engagement-ring-wearing .o-page-section--after .page-wrap {
    text-align: center;
}