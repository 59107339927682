/* ===========================
Landing page article item - component
=========================== */
.l-lp-article-list {
     display: grid;
     gap: var(--space-12);
     margin: 0;
     padding: 0;
     list-style-type: none;

     @include breakpoint(min-768) {
        grid-template-columns: repeat(2,1fr);
        gap: var(--space-16) var(--space-4);
    }

    @include breakpoint(min-1280) {
        grid-template-columns: repeat(4,1fr);
        gap: var(--space-20) var(--space-6);
    }
 }

.c-lp-article-item {
}

.c-lp-article-item__image-link {
    overflow: hidden;
    display: block;
    margin-bottom: rem(24);
    aspect-ratio: 517 / 280;
}

.c-lp-article-item img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    display: block;
    transition: transform .4s ease-in;


    &:hover {
        transform: scale(1.05);
    }
}

.c-lp-article-item h2 {
    font-family: $font-stack-text;
    font-weight: var(--font-weight-semibold);
    font-size: var(--font-size-6);
    line-height: rem(26);
    margin-bottom: var(--space-2);

    @include breakpoint(min-768) {
        text-align: left;
        font-size: var(--font-size-7);
        line-height: var(--line-height-5);
        margin-bottom: var(--space-3);
    }
}

.c-lp-article-item p {
    display: block;
    color: var(--gray-70);
    font-size: var(--font-size-4);
    line-height: var(--line-height-3);
    margin: 0 0 var(--space-4) 0;

    @include breakpoint(min-1280) {
        margin: 0 0 var(--space-6) 0;
    }
}

 //Component edit for 5C sitemap & about jewels page
 .lp-jewelry-guide--jewels-diamonds-5c-sitemap, .o-page-section--about-section {
    .l-lp-article-list {
        @include breakpoint(min-1280) {
            grid-template-columns: repeat(3,1fr);
        }
    }
 }
