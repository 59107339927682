:root {
	--shadow-color: 0deg 0% 72%;
	--shadow-elevation-low:
		0px 0.3px 0.4px hsl(var(--shadow-color) / 0.11),
		0px 0.6px 0.8px -0.6px hsl(var(--shadow-color) / 0.2),
		0px 1.2px 1.6px -1.2px hsl(var(--shadow-color) / 0.29);
	--shadow-elevation-medium:
		0px 0.3px 0.4px hsl(var(--shadow-color) / 0.12),
		0.1px 1.3px 1.7px -0.4px hsl(var(--shadow-color) / 0.19),
		0.1px 2.7px 3.6px -0.8px hsl(var(--shadow-color) / 0.26),
		0.2px 5.9px 7.8px -1.2px hsl(var(--shadow-color) / 0.33);
	--shadow-elevation-high:
		0px 0.3px 0.4px hsl(var(--shadow-color) / 0.13),
		0.1px 2.4px 3.2px -0.2px hsl(var(--shadow-color) / 0.17),
		0.2px 4.3px 5.7px -0.4px hsl(var(--shadow-color) / 0.21),
		0.3px 6.9px 9.2px -0.6px hsl(var(--shadow-color) / 0.25),
		0.4px 10.6px 14.1px -0.8px hsl(var(--shadow-color) / 0.29),
		0.7px 16.3px 21.7px -1px hsl(var(--shadow-color) / 0.34),
		1px 24.6px 32.7px -1.2px hsl(var(--shadow-color) / 0.38);
}

/* Variables */

$basefont: 16;
$page-context: 1600;
$font-stack-display: "Canela web", sans-serif;
$font-stack-text: "plusjakarta", "HelveticaNeue-Light", "Helvetica Neue Light", "Helvetica Neue", Helvetica, Arial, sans-serif;


/* Functions */

@function percent($target, $container) {
	@return calc($target / $container) * 100%;
}

@function em($target, $context: $basefont) {
	@return calc($target / $context) * 1em;
}


@function rem($size) {
  $remSize: calc($size / 16);
  @return $remSize + rem;
}


/* Breakpoints */

@mixin breakpoint($point) {

	@if $point == min-480 {
		@media screen and (min-width: 480px) { @content; }
	}

	@else if $point == min-640 {
		@media screen and (min-width: 640px) { @content; }
	}

	@else if $point == min-768 {
		@media screen and (min-width: 768px) { @content; }
	}

	@else if $point == min-960 {
		@media screen and (min-width: 960px) { @content; }
	}

	@else if $point == min-1024 {
		@media screen and (min-width: 1024px) { @content; }
	}

	@else if $point == min-1280 {
		@media screen and (min-width: 1280px) { @content; }
	}

	@else if $point == min-1600 {
		@media screen and (min-width: 1600px) { @content; }
	}

}


/* Retina images */

@mixin image-2x($image, $width, $height:"") {
	@media (min--moz-device-pixel-ratio: 1.3),
				 (-o-min-device-pixel-ratio: 2.6/2),
				 (-webkit-min-device-pixel-ratio: 1.3),
				 (min-device-pixel-ratio: 1.3),
				 (min-resolution: 1.3dppx) {
		background-image: url($image);
		background-size: $width $height;
	}
}


// Mixins itself

@mixin clearfix() {
  &::after {
    display: block;
    content: "";
    clear: both;
  }
}

@keyframes loader-rotate {
	0% {
	transform: rotate(0);
	}

	100% {
	transform: rotate(360deg);
	}
}


/* Z-Index Scale */

$z-index-1:   100;
$z-index-2:   200;
$z-index-3:   300;
$z-index-4:   400;
$z-index-5:   500;
$z-index-6:   600;
$z-index-7:   700;
$z-index-8:   800;
$z-index-9:   900; /* Loader */
$z-index-10: 1000; /* header */
$z-index-11: 1100; /* used for chat which has to be over header  */
$z-index-12: 1200; /* used for cookie modal which has to be over everything */


/* Cubic bezier transitions */
$ease-in-quad: cubic-bezier(.55, .085, .68, .53);
$ease-in-cubic: cubic-bezier(.550, .055, .675, .19);
$ease-in-quart: cubic-bezier(.895, .03, .685, .22);
$ease-in-quint: cubic-bezier(.755, .05, .855, .06);
$ease-in-expo: cubic-bezier(.95, .05, .795, .035);
$ease-in-circ: cubic-bezier(.6, .04, .98, .335);

$ease-out-quad: cubic-bezier(.25, .46, .45, .94);
$ease-out-cubic: cubic-bezier(.215, .61, .355, 1);
$ease-out-quart: cubic-bezier(.165, .84, .44, 1);
$ease-out-quint: cubic-bezier(.23, 1, .32, 1);
$ease-out-expo: cubic-bezier(.19, 1, .22, 1);
$ease-out-circ: cubic-bezier(.075, .82, .165, 1);

$ease-in-out-quad: cubic-bezier(.455, .03, .515, .955);
$ease-in-out-cubic: cubic-bezier(.645, .045, .355, 1);
$ease-in-out-quart: cubic-bezier(.77, 0, .175, 1);
$ease-in-out-quint: cubic-bezier(.86, 0, .07, 1);
$ease-in-out-expo: cubic-bezier(1, 0, 0, 1);
$ease-in-out-circ: cubic-bezier(.785, .135, .15, .86);