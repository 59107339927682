/* ===========================
Piercing studio page
=========================== */

.lp-jewelry-guide--piercing-studio .piercing-placement, .lp-jewelry-guide--piercing-studio .piercing-faq {
  background-color: var(--gray-05);
  padding-top: var(--space-6);

  @include breakpoint(min-640) {
    padding-top: var(--space-10);
  }

  @include breakpoint(min-1280) {
    padding-top: var(--space-16);
  }

  @include breakpoint(min-1600) {
    padding-top: var(--space-20);
  }
}

.lp-jewelry-guide--piercing-studio .piercing-placement {
  margin-bottom: var(--space-20);

  @include breakpoint(min-768) {
    margin-bottom: rem(100);
  }
}

.lp-jewelry-guide--piercing-studio .piercing-placement img {
  max-width: 582px;
}

.lp-jewelry-guide--piercing-studio .piercing-placement .list {
  counter-reset: item;
}

.lp-jewelry-guide--piercing-studio .piercing-placement .list-item {
  text-align: left;
  padding-left: var(--space-8);

  @include breakpoint(min-768) {
    padding-left: var(--space-10);
  }

  @include breakpoint(min-1280) {
    padding-left: var(--space-16);
  }
}

.lp-jewelry-guide--piercing-studio .piercing-placement .list-item:before {
  position: absolute;
  top: 0;
  left: 0;
  color: var(--gold-50, #AE906F);
  font-family: $font-stack-display;
  font-size: var(--font-size-8);
  line-height: rem(32);
  counter-increment: item;
  content: counter(item);

  @include breakpoint(min-768) {
    font-size: var(--font-size-9);
    line-height: rem(40);
  }

  @include breakpoint(min-1280) {
    font-size: var(--font-size-10);
    line-height: rem(60);
  }
}

.lp-jewelry-guide--piercing-studio .piercing-placement .list--2 {
  padding: var(--space-10) 0 0 0;
  margin: 0;
  display: grid;
  gap: var(--space-6);
  grid-template-columns: repeat(1, 1fr);

  @include breakpoint(min-960) {
    grid-template-columns: repeat(2, 1fr);
  }

  @include breakpoint(min-1280) {
    padding-top: var(--space-16);
    grid-template-columns: repeat(4, 1fr);
  }

  @include breakpoint(min-1600) {
    padding-top: var(--space-20);
  }
}

.lp-jewelry-guide--piercing-studio .piercing-placement .list--2 .o-page-section__heading-4 {
  text-align: left;
}

.lp-jewelry-guide--piercing-studio .piercing-faq .faq-list {
  display: flex;
  flex-direction: column;
  gap: var(--space-2);
  width: 100%;
  max-width: 940px;
  margin: var(--space-6) auto 0 auto;
  padding: 0;
  list-style-type: none;

  @include breakpoint(min-768) {
    margin-top: var(--space-10);
  }

  @include breakpoint(min-1280) {
    margin-top: var(--space-20);
  }
}

.lp-jewelry-guide--piercing-studio .piercing-faq .faq-list-item {
  background-color: var(--white);
}

.lp-jewelry-guide--piercing-studio .piercing-faq .faq-list-item[open] summary {
  padding-bottom: 0;
}

.lp-jewelry-guide--piercing-studio .piercing-faq .faq-list-item[open] summary .icon {
  transform: rotate(-180deg);
}

.lp-jewelry-guide--piercing-studio .piercing-faq .faq-list-item summary {
  font-size: var(--font-size-4);
  line-height: var(--line-height-2);
  font-weight: var(--font-weight-bold);
  color: var(--gray-90);
  padding: var(--space-5) var(--space-6);
  cursor: pointer;
  display: flex;
  gap: var(--space-4);

  @include breakpoint(min-768) {
    font-size: var(--font-size-5);
    line-height: var(--line-height-3);
    padding: var(--space-9) var(--space-10);
  }

  &:focus {
    background-color: var(--white);
  }
}

.lp-jewelry-guide--piercing-studio .piercing-faq .faq-list-item summary::-webkit-details-marker {
  display:none;
}

.lp-jewelry-guide--piercing-studio .piercing-faq .faq-list-item summary .icon {
  fill: var(--gray-50);
  width: rem(16);
  height: rem(24);
  flex: 0 0 auto;
  margin-left: auto;
  transition: transform .2s $ease-in-out-quad;
}

.lp-jewelry-guide--piercing-studio .piercing-faq .faq-list-item p {
  font-size: var(--font-size-3);
  line-height: rem(22);
  color: var(--gray-70);
  padding: 0 var(--space-6) var(--space-5) var(--space-6);

  @include breakpoint(min-768) {
    padding: 0 var(--space-10) var(--space-9) var(--space-10);
  }
}

.lp-jewelry-guide--piercing-studio .o-page-section__image--piercer {
  @include breakpoint(min-1280) {
    max-width: rem(640);
    margin-left: auto;
  }
}
