/* ===========================
Promo
=========================== */

.promo-page-content {
    background: var(--gray-10);
}

.promo-content {
    background: var(--white);
    padding: rem(20) 0 rem(32) 0;

    @include breakpoint(min-640) {
        padding: rem(32) 0 rem(40) 0;
    }

    @include breakpoint(min-960) {
        padding: rem(40) 0 rem(60) 0;
    }

    @include breakpoint(min-1600) {
        padding: rem(60) 0 rem(80) 0;
    }

    h1 {
        font-family: $font-stack-display;
        font-weight: var(--font-weight-regular);
        font-size: var(--font-size-7);
        line-height: rem(28);
        max-width: 70ch;
        text-align: center;
        margin: 0 auto rem(20) auto;

        @include breakpoint(min-640) {
            font-size: var(--font-size-7);
            line-height: rem(36);
        }

        @include breakpoint(min-960) {
            font-size: var(--font-size-8);
            line-height: rem(40);
        }

        @include breakpoint(min-1600) {
            font-size: var(--font-size-9);
            line-height: rem(58);
        }
    }

    p {
        color: var(--gray-80);
        text-align: center;
        max-width: 70ch;
        margin: 0 auto rem(16) auto;
        line-height: rem(24);
    }

    > h2 {
        font-size: var(--font-size-6);
        line-height: rem(24);
        max-width: 70ch;
        text-align: center;
        margin: rem(40) auto rem(12) auto;

        @include breakpoint(min-640) {
            font-size: var(--font-size-7);
            line-height: rem(28);
        }

        @include breakpoint(min-960) {
            font-size: var(--font-size-7);
            line-height: rem(32);
        }

        @include breakpoint(min-1600) {
            font-size: var(--font-size-8);
            line-height: rem(40);
        }
    }

    a {
        color: var(--gray-90);
    }
}


.vanoce-2018-collection {
    padding: rem(24) 0 rem(32) 0;
    background-color: var(--white);

    @include breakpoint(min-640) { padding: rem(32) 0 rem(44) 0; }
    @include breakpoint(min-960) { padding: rem(44) 0 rem(52) 0; }
    @include breakpoint(min-1600) { padding: rem(52) 0 rem(60) 0; }

    .page-wrap > .btn {
        display: block;
        max-width: rem(360);
        margin: 0 auto rem(80) auto;
    }

    h3 {
        font-size: var(--font-size-4);
        line-height: rem(20);
        max-width: 70ch;
        text-align: center;
        margin: rem(20) auto rem(40) auto;

        @include breakpoint(min-640) {
            font-size: var(--font-size-6);
            line-height: rem(28);
        }

        @include breakpoint(min-960) {
            font-size: var(--font-size-7);
            line-height: rem(32);
        }

        @include breakpoint(min-1600) {
            font-size: var(--font-size-7);
            line-height: rem(40);
        }
    }

    p {
        color: var(--gray-80);
        text-align: center;
        max-width: 70ch;
        margin: 0 auto rem(40) auto;
        line-height: rem(24);

        @include breakpoint(min-960) {
            font-size: var(--font-size-5);
            line-height: rem(24);
            margin: 0 auto rem(80) auto;
        }
    }

    h4 {
        font-size: var(--font-size-5);
        text-transform: uppercase;
        line-height: rem(16);
        max-width: 70ch;
        text-align: center;
        margin: rem(20) auto rem(40) auto;


        @include breakpoint(min-960) {
            font-size: var(--font-size-6);
            line-height: rem(32);
        }

        @include breakpoint(min-1600) {
            font-size: var(--font-size-7);
            line-height: rem(40);
        }
    }
}

.voucher-form {
    width: 100%;
    max-width: rem(400);
    margin: rem(40) auto rem(80) auto;

    .selectbox {
        width: 100%;
        margin-bottom: rem(16);
    }

    label {
        margin-bottom: rem(32);
    }

    .btn {
        width: 100%;
    }
}

.lp-products {
	padding: rem(32) 0 rem(44) 0;
	background-color: var(--white);

	@include breakpoint(min-640) { padding: rem(44) 0 rem(52) 0; }
	@include breakpoint(min-960) { padding: rem(52) 0 rem(60) 0; }
	@include breakpoint(min-1600) { padding: rem(60) 0 rem(80) 0; }
}

.lp-products .page-wrap > h2 {
	text-align: center;
	font-family: $font-stack-display;
	font-weight: var(--font-weight-regular);
	margin-bottom: rem(16);

	@include breakpoint(min-640) {
		font-size: var(--font-size-7);
		line-height: rem(40);
	}

	@include breakpoint(min-768) {
		font-size: var(--font-size-9);
		line-height: rem(52);
	}

	@include breakpoint(min-1280) {
		margin-bottom: rem(32);
	}
}

.lp-products h3 {
	text-align: center;
	margin-bottom: rem(8);

	@include breakpoint(min-640) {
		font-size: var(--font-size-6);
		line-height: rem(24);
	}

	@include breakpoint(min-768) {
		font-size: var(--font-size-7);
		line-height: rem(36);
	}

	@include breakpoint(min-1280) {
		margin-bottom: rem(32);
	}
}

.lp-products .page-wrap > .btn {
	display: block;
	max-width: rem(360);
	margin: 0 auto;
}

.lp-products p {
	color: var(--gray-80);
	text-align: center;
	max-width: 70ch;
	margin: 0 auto rem(40) auto;
	line-height: rem(24);

	@include breakpoint(min-960) {
		font-size: var(--font-size-5);
		line-height: rem(24);
		margin: 0 auto rem(80) auto;
	}
}

.lp-products .products {
	margin-bottom: rem(20);

	@include breakpoint(min-640) {
		margin-bottom: rem(60);
	}

	@include breakpoint(min-960) {
		margin-bottom: rem(80);
	}
}

.christmas-2020 {
	.bf-cover {
		background-color: var(--gray-90);

		.c-cover-image-box__heading {
			color: var(--white);
		}
	}

	.christmas-intro {
		padding: rem(32) 0 rem(44) 0;
		background-color: var(--white);
		padding-bottom: rem(20);
	
		@include breakpoint(min-640) { padding: rem(44) 0 rem(52) 0; }
		@include breakpoint(min-960) { padding: rem(52) 0 rem(60) 0; }
		@include breakpoint(min-1600) { padding: rem(60) 0 rem(80) 0; }
	
		p {
			text-align: center;
			max-width: 70ch;
			margin: 0 auto rem(16) auto;

			@include breakpoint(min-960) {
				font-size: var(--font-size-5);
				margin: 0 auto rem(24) auto;
			}
		}
	
		h2 {
			text-align: center;
			margin: rem(40) 0 rem(20) 0;
		}

		.btn {
			max-width: rem(360);
			margin: rem(40) auto 0 auto;
			display: block;
		}

		img {
			display: block;
			margin: rem(40) auto rem(32) auto;
		}

	}

	.christmas-products {
		padding: rem(32) 0 rem(44) 0;
		background-color: var(--white);
	
		@include breakpoint(min-640) { padding: rem(44) 0 rem(52) 0; }
		@include breakpoint(min-960) { padding: rem(52) 0 rem(60) 0; }
		@include breakpoint(min-1600) { padding: rem(60) 0 rem(80) 0; }

		.heading {
			text-align: center;
		}
	
		.page-wrap > .btn {
			display: block;
			max-width: rem(360);
			margin: 0 auto;
		}
	
		p {
			color: var(--gray-80);
			text-align: center;
			max-width: 70ch;
			margin: 0 auto rem(40) auto;
			line-height: rem(24);
	
			@include breakpoint(min-960) {
				font-size: var(--font-size-5);
				line-height: rem(24);
				margin: 0 auto rem(80) auto;
			}
		}
	}

}



.christmas-section {
	background-color: var(--white);
	text-align: center;
	padding: rem(40) 0;

	@include breakpoint(min-768) {
		padding: rem(60) 0;
	}

	@include breakpoint(min-960) {
		padding: rem(80) 0;
	}
}

.christmas-section h1 {
	margin-bottom: var(--space-4);
}

.christmas-section h1 ~ p {
	max-width: 90ch;
}

.christmas-section--gray {
	background-color: var(--gray-10);
	margin: 0 0 var(--space-10) 0;

	@include breakpoint(min-768) {
		margin: 0 0 var(--space-16) 0;
	}

	@include breakpoint(min-960) {
		margin: 0 0 var(--space-20) 0;
	}
}

.christmas-section p {
	margin-bottom: rem(40);
	text-align: center;
    max-width: 70ch;
    margin: 0 auto 2.5rem auto;
}
    

.c-christmas-2022-signpost {
	display: flex;
	list-style-type: none;
	margin: 0;
	padding: 0;
	width: 100%;
	justify-content: center;
	flex-direction: column;

	@include breakpoint(min-768) {
		flex-direction: row;
	}
}

.c-christmas-2022-signpost__item {
	border-radius: rem(4);
	margin: rem(4);
	padding: rem(40) rem(16);
	text-align: center;
	display: flex;
	align-items: center;
	flex-direction: column;

	@include breakpoint(min-768) {
		flex: 0 0 rem(400);
		padding: rem(60) rem(32);
	}
}

.c-christmas-2022-signpost__item .icon {
	width: rem(60);
	height: rem(60);
	display: block;
	margin-bottom: rem(40);
}

.c-christmas-2022-signpost__item--female {
	background-color: var(--pink-20);
}

.c-christmas-2022-signpost__item--female p {
	color: var(--pink-90);
}

.c-christmas-2022-signpost__item--female .icon {
	fill: var(--pink-50);
}

.c-christmas-2022-signpost__item--male {
	background-color: var(--gold-20);
}

.c-christmas-2022-signpost__item--male p {
	color: var(--gold-90);
}

.c-christmas-2022-signpost__item--male .icon {
	fill: var(--gold-50);
}

.c-christmas-2022-signpost__item:hover {
	box-shadow: 0 rem(1) rem(20) 0 rgba(26,22,18,0.15);
}

.christmas-2022-benefit {
	background-color: var(--gray-10);
	text-align: center;
	padding: rem(40) 0;

	@include breakpoint(min-768) {
		padding: rem(60) 0;
	}

	@include breakpoint(min-960) {
		padding: rem(80) 0;
	}
}

.c-christmas-promo-box {
	display: flex;
	justify-content: center;
	flex-direction: column;
	align-items: center;
	max-width: rem(960);
	margin: 0 auto;
	background-color: var(--white);
	padding: var(--space-6) var(--space-6) var(--space-20) var(--space-6);

	@include breakpoint(min-768) {
		flex-direction: row;
		padding: var(--space-6);
	}

}

.c-christmas-promo-box--reverse {

	@include breakpoint(min-768) {
		flex-direction: row-reverse;
	}

}

.c-christmas-promo-box__image {
	width: 100%;
	max-width: 100%;
	margin-bottom: var(--space-6);

	@include breakpoint(min-768) {
		margin-right: var(--space-10);
		margin-bottom: 0;
		max-width: rem(400);
	}
	
}

.c-christmas-promo-box__image img {
	max-width: 100%;	
	height: auto;
}

.c-christmas-promo-box--reverse .c-christmas-promo-box__image {

	@include breakpoint(min-768) {
		margin-right: 0;
		margin-left: var(--space-10);
	}

}

.c-christmas-promo-box__links {
	list-style-type: none;
	margin: 0;
	padding: 0;
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
}

.c-christmas-promo-box__links li { 
	flex: 0 0 50%;
	padding: var(--space-2) 0;
}

.christmas-heading,
.christmas-paragraph {
	margin-bottom: rem(80) !important;
}

.christmas-heading span {
	margin-top: rem(8);
	display: block;
	color: var(--gray-70);
	font-weight: var(--font-weight-regular);
}


.christmas-categories {
	background-color: var(--gray-10);
	padding: rem(20) 0 rem(40) 0;

	@include breakpoint(min-640) {
		padding: rem(40) 0 rem(60) 0;
	}

	@include breakpoint(min-768) {
		padding: rem(60) 0 rem(80) 0;
	}

	@include breakpoint(min-1024) {
		padding: rem(80) 0 rem(100) 0;
	}

	@include breakpoint(min-1600) {
		padding: rem(100) 0 rem(140) 0;
	}

	h2 {
		text-align: center;
		margin: 0 auto rem(28) auto;

		@include breakpoint(min-768) {
			max-width: percent(640, 768);
		}

		@include breakpoint(min-960) {
			max-width: percent(640, 960);
			
		}

		@include breakpoint(min-1024) {
			max-width: 100%;
			text-align: center;
		}
	}

	.c-collection-cover-subcat {

		&__item {
			background: var(--white);
			border-radius: rem(2);
			margin: rem(4);
			position: relative;
			padding: rem(20);
			flex: 0 0 calc(50% - rem(48));

			@include breakpoint(min-960) {
				flex: 0 0 calc(25% - rem(48));
			}
	
			&:hover {
				box-shadow: 0 rem(1) rem(20) 0 rgba(26,22,18,0.15);
			}
			
			&:last-child {
				margin: rem(4);
			}
	
		}
	
		&__item-container {
			display: block;
			height: 100%;
		}
	
		&__item-image {
			flex: 0 0 rem(100);
			margin: 0 auto rem(12) auto;
			display: block;
			max-width: 100%;
			height: auto;
		}
		
		&__item-name {
			align-self: center;
			flex: 1 1 100%;
			width: 100%;
			height: 100%;
	
			@include breakpoint(min-640) {
				text-align: center;
			}
			
			a {
				background-color: var(--gray-90);
				color: var(--white);
				display: block;
				font-size: var(--font-size-4);
				line-height: rem(20);
				padding: rem(12) 0;
				max-width: 80%;
				margin: rem(20) auto;
				text-align: center;
			}
	
		}
	}


}


// New styles for promo pages


.l-promo-page {
	display: flex;
	flex-direction: column;
}

.c-promo-section {
	background-color: var(--gray-10);
	padding: var(--space-10) 0;	

	@include breakpoint(min-768) {
		padding: var(--space-12) 0;	
	}
	
	@include breakpoint(min-1024) {
		padding: var(--space-16) 0;	
	}

	@include breakpoint(min-1280) {
		padding: var(--space-20) 0;	
	}

	@include breakpoint(min-1600) {
		padding: var(--space-24) 0;	
	}
}

.c-promo-section p {
	max-width: 70ch;
	margin: 0 0 var(--space-10);
}

.c-promo-section--white {
	background-color: var(--white);
}

.c-promo-section,
.c-promo-section > .u-page-wrap {
	display: flex;
	flex-direction: column;
}

.c-promo-section--center,
.c-promo-section--center > .u-page-wrap {
	align-items: center;
	text-align: center;
}

// Marianne days

.c-cover-image-fullscreen--marianne .c-cover-image-fullscreen__heading {
	color: var(--pink-90);
}
.c-cover-image-fullscreen--marianne .c-cover-image-fullscreen__desc {
	font-size: var(--font-size-6);
	color: var(--pink-70);
}

.l-promo-page--marianne .collection-cover-subcat,
.l-promo-page--marianne .products {
	margin-bottom: var(--space-16);
}

.l-promo-page--marianne .collection-cover-subcat__item {
	flex: 1 1 20%;
}

.l-promo-page--marianne .collection-cover-subcat__item img {
	object-fit: contain;
}


.c-promo-category-nav {
	list-style-type: none;
	margin: 0 0 var(--space-16) 0;
	padding: 0;
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
}

.c-promo-category-nav__item {
	margin: var(--space-4);
}

.c-promo-category-nav__item img {
	display: block;
}

.c-promo-category-nav__item-name h3 {
	font-family: $font-stack-text;
	font-weight: var(--font-semibold);
	font-size: var(--font-size-5);
}

.c-app-download {
	list-style-type: none;
	margin: 0;
	padding: 0;
	display: flex;
	flex-direction: column;
	align-items: center;

	@include breakpoint(min-768) {
		flex-direction: row;
	}
}

.c-app-download__item {
	margin: var(--space-1);
}

.mastercard-gift {
	display: flex;
	justify-content: center;
	flex-direction: column;
	align-items: center;
	max-width: rem(800);

	@include breakpoint(min-640) {
		flex-direction: row;
	}
}

.mastercard-gift figcaption{
	padding: var(--space-10);
	font-size: var(--font-size-7);
	flex: 1 1 50%;
	display: flex;
	align-items: center;
	text-align: center;

	@include breakpoint(min-640) {
		text-align: left;
	}
	
}

// Marianne/Ona dnes page
.lp-jewelry-guide--marianne-promo .marianne-app-image, .lp-jewelry-guide--marianne-promo .mastercard-image, .lp-jewelry-guide--ona-dnes-promo .visa-image, .lp-jewelry-guide--denik-promo .denik-app-image {
	@include breakpoint(min-1280) {
		max-width: rem(640);
		margin-left: auto;
	}
}

.sale-code-usage {
	background-color: var(--gray-05);
	padding-top: var(--space-6);

	@include breakpoint(min-640) {
		padding-top: var(--space-10);
	}

	@include breakpoint(min-1280) {
		padding-top: var(--space-16);
	}

	@include breakpoint(min-1600) {
		padding-top: var(--space-20);
	}
}

.sale-code-usage {
	margin-bottom: var(--space-20);

	@include breakpoint(min-768) {
		margin-bottom: rem(100);
	}
}

.sale-code-usage .list {
	padding: var(--space-10) 0 0 0;
	margin: 0;
	display: grid;
	gap: var(--space-6);
	grid-template-columns: repeat(1, 1fr);
	list-style-type: none;

	@include breakpoint(min-960) {
		gap: var(--space-10);
		grid-template-columns: repeat(3, 1fr);
	}

	@include breakpoint(min-1600) {
		padding-top: var(--space-20);
	}
}

.sale-code-usage .list img {
	max-width: 100%;
	border: var(--space-05) solid var(--gray-2r0);
}

.sale-code-usage .list li {
	margin: 0;
	padding: 0;
	display: flex;
	flex-direction: column;
	gap: var(--space-6);
	text-align: center;
}

.sale-code-usage .list li .o-page-section__desc-small {
	text-align: center;
}

.lp-jewelry-guide--marianne-promo .o-page-section--promo, .lp-jewelry-guide--ona-dnes-promo .o-page-section--promo, .lp-jewelry-guide--denik-promo .o-page-section--promo {
	text-align: center;
	justify-content: center;
}