/* ===========================
Ring selection page
=========================== */
.lp-jewelry-guide--ring-selection .o-page-section--rings-profile {
    max-width: none;
    width: 100%;
    flex-wrap: wrap;
    padding-left: 0;
    padding-right: 0;
}

.lp-jewelry-guide--ring-selection .o-page-section--rings-surface .o-page-section__image img {
    width: 100%;
    max-width: rem(412);

    @include breakpoint(min-640) {
        max-width: rem(720);
    }
}

.lp-jewelry-guide--ring-selection .o-page-section--stones .o-page-section__image img {
    @include breakpoint(min-1280) {
        max-width: rem(470);
    }
}

.lp-jewelry-guide--ring-selection .rings-profile-block {
    width: 100%;
    background-color: var(--gray-10);
    margin-top: rem(40);
    padding: rem(24) 0 rem(40) 0;

    @include breakpoint(min-768) {
        margin-top: rem(80);
        padding: rem(40) 0 rem(60) 0;
    }
}

.lp-jewelry-guide--ring-selection .rings-profile-list {
    margin: rem(-12);
    padding: 0;
    list-style-type: none;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.lp-jewelry-guide--ring-selection .rings-profile-list__item {
    margin: rem(12);
    flex: 1 1 100%;
    text-align: center;

    @include breakpoint(min-640) {
        flex: 1 1 calc(50% - #{rem(24)});
        max-width:  calc(50% - #{rem(24)});
    }

    @include breakpoint(min-960) {
        flex: 1 0 calc(20% - #{rem(24)});
        max-width:  calc(20% - #{rem(24)});
        min-width: rem(240);
    }
}

.lp-jewelry-guide--ring-selection .rings-profile-list__item img {
    width: 100%;
    max-width: rem(202);
    height: auto;
    margin-bottom: rem(16);
}

.lp-jewelry-guide--ring-selection .rings-profile-list__item .o-page-section__heading-3, .lp-jewelry-guide--ring-selection .rings-profile-list__item .o-page-section__desc-small {
    text-align: center;
}