/* ===========================
Weeding ring measure page
=========================== */

.lp-jewelry-guide--weeding-ring-measure {
    .note {
        color: var(--sc-ruby-red, #BE1E2D);
        font-weight: var(--font-weight-semibold);
    }

    .ring-limitations {
        text-align: center;
        margin: 0 auto;

        .o-page-section__heading-3 {
            max-width: rem(540);
            margin: rem(12) auto rem(24) auto;
            text-align: center;

            @include breakpoint(min-640) {
                margin: rem(20) auto rem(40) auto;
            }

            @include breakpoint(min-768) {
                margin: rem(24) auto rem(60) auto;
            }

            @include breakpoint(min-1600) {
                margin: rem(32) auto rem(80) auto;
            }

        }
    }

    .ring-limitations-list {
        display: inline-flex;
        flex-wrap: wrap;
        margin: rem(-4);
        padding: 0;
        list-style-type: none;

        @include breakpoint(min-768) {
            flex-wrap: nowrap;
            margin: rem(-8);
        }
    }

    .ring-limitations-list__item {
        overflow: hidden;
        width: 100%;
        margin: rem(4);
        display: flex;
        height: rem(400);

        @include breakpoint(min-640) {
            height: rem(280);
        }

        @include breakpoint(min-768) {
            width: calc(33.33% - #{rem(16)});
            margin: rem(8);
            height: rem(548);
        }

        img {
            position: absolute;
            width: auto;
            height: 100%;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);

            @include breakpoint(min-640) {
                max-width: 100%;
                height: auto;
            }

            @include breakpoint(min-768) {
                max-width: none;
                height: 100%;
            }
        }

        p {
            text-align: center;
            margin: auto auto 0 auto;
            position: relative;
            z-index: 1;
            padding: rem(40);
        }
    }
}