/* ===========================
Reclamation page
=========================== */
.reclamation-page {
    .reclamation-info {
        margin: 0;
        padding: rem(24) 0;
        background-color: var(--gray-10);

        @include breakpoint(min-480) {
            padding: rem(32) 0;
        }

        @include breakpoint(min-768) {
            padding: rem(40) 0;
        }

        @include breakpoint(min-1280) {
            padding: rem(60) 0;
        }

        .reclamation-info-blocks {
            margin: 0 auto;
            padding: 0;
            list-style-type: none;
            width: 100%;
            max-width: rem(929);

            &__item {
                background-color: var(--white);
                margin: 0 0 rem(8) 0;
                padding: rem(20) rem(24);

                @include breakpoint(min-768) {
                    padding: rem(38) rem(40);
                }

                &:last-of-type {
                    margin: 0;
                }

                h3 {
                    font-size: var(--font-size-6);
                    line-height: rem(28);
                    font-family: $font-stack-display;
                    font-weight: var(--font-weight-regular);
                    margin-bottom: rem(16);

                    @include breakpoint(min-768) {
                        font-size: var(--font-size-7);
                        line-height: rem(32);
                        margin-bottom: rem(24);
                    }
                }

                p {
                    color: var(--gray-70);
                    font-size: var(--font-size-5);
                    line-height: rem(28);
                    letter-spacing: -0.6px;
                    margin-top: rem(16);

                    &:last-child {
                        margin-bottom: 0;
                    }

                    @include breakpoint(min-768) {
                        margin-top: rem(24);
                    }

                    strong {
                        color: var(--gray-90);
                    }
                }

                ul {
                    margin: rem(16) 0 rem(16) rem(20);
                    padding: 0;
                    list-style-type: none;

                    li {
                        font-size: var(--font-size-4);
                        line-height: rem(24);
                        padding: 0 0 0 rem(24);
                        margin: 0 0 rem(16) 0;

                        &:last-child {
                            margin: 0;
                        }

                        &::before {
                            content: "";
                            width: rem(8);
                            height: rem(2);
                            background-color: var(--gray-40);
                            position: absolute;
                            left: 0;
                            top: rem(12);
                        }
                    }
                }
            }
        }
    }
}