/* ===========================
Jewels diamonds 1C page
=========================== */
.lp-jewelry-guide--jewels-diamonds-1c {
    .o-page-section--diamond-1c-measuring img {
        margin-top: rem(40);
        width: 100%;
        height: auto;
        display: block;
    }

    .recalculation-box {
        border-radius: rem(6);
        background-color: var(--gray-10);
        padding: rem(24);
        font-family: $font-stack-display;
        font-size: var(--font-size-6);
        line-height: rem(32);
        color: var(--gray-70);
        text-align: center;
        margin: rem(80) auto 0 auto;
        max-width: rem(440);

        @include breakpoint(min-768) {
            font-size: var(--font-size-8);
            line-height: rem(52);
            margin: rem(160) auto 0 auto;
        }
    }

    .recalculation-box abbr {
        border-bottom: none;
    }
}