/* ===========================
Showroom section - component
=========================== */
.c-showroom-section {
    overflow: hidden;
    margin: 0;
    display: flex;
    flex-wrap: wrap;
    background-color: var(--white);
    padding: rem(32) 0;

    @include breakpoint(min-768) {
        min-height: rem(560);
    }

    @include breakpoint(min-1280) {
        padding: rem(60) 0;
        min-height: rem(800);
    }

    @include breakpoint(min-1600) {
        padding: rem(80) 0;
    }
}

//page-wrap
.c-showroom-section .page-wrap {
    display: flex;
    flex-wrap: wrap;

    @include breakpoint(min-1280) {
        min-height: rem(800);
        align-items: center;
        flex-wrap: nowrap;
    }
}

.c-showroom-section__content {
    text-align: center;
    margin: rem(24) auto;
    flex: 0 0 92%;
    order: 1;

    @include breakpoint(min-768) {
        text-align: left;
        margin: rem(32) auto;
    }

    @include breakpoint(min-1280) {
        margin: 0 4% 0 0;
        flex: 1 1 50%;
        max-width: 50%;
    }
}

.c-showroom-section__section-heading {
    display: block;
    color: var(--gray-40);
    font-size: var(--font-size-1);
    line-height: rem(18);
    text-transform: uppercase;
    letter-spacing: 1.5px;
    margin: 0 0 var(--space-2) 0;
}

.c-showroom-section__heading {
    display: block;
    margin-bottom: var(--space-2);
   
    @include breakpoint(min-768) {
        margin-bottom: var(--space-4);
    }
}

.c-showroom-section__desc {
    display: block;
    color: var(--gray-70);
    margin: 0 0 var(--space-8) 0;

    @include breakpoint(min-768) {
        margin: 0 0 var(--space-10) 0;
    }

    @include breakpoint(min-1280) {
        margin: 0 0 var(--space-16) 0;
    }
}

.c-showroom-section__btn-wrapper {
   display: inline-flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: var(--space-2);
}

.c-showroom-section__image-wrapper {
    position: relative;
    margin: 0 auto;
    order: 0;
    aspect-ratio: 640 / 240;

    @include breakpoint(min-640) {
        height: auto;
    }

    @include breakpoint(min-1280) {
        flex: 1 0 50%;
        max-width: rem(760);
        margin-right: 0;
        order: 1;
        aspect-ratio: 760 / 670;
    }
}

.c-showroom-section__image-1 {
    position: absolute;
    top: 0;
    left: 0;
    animation: swapImages 8s infinite;
}

@keyframes swapImages {
    0% {opacity: 1;}
    40% {opacity: 1;}
    50% {opacity: 0;}
    90% {opacity: 0;}
    100% {opacity: 1;}
}
.c-showroom-section__image-1, .c-showroom-section__image-2 {
    width: 100%;
    height: 100%;
    object-fit: fill;
}